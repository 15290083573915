import React, { useState, useEffect } from "react";
import {
  Box,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  Table,
  Collapse,
  IconButton,
  styled,
} from "@mui/material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import {
  BarChart,
  Bar,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import { format } from "date-fns";
import CustomTick from "./customTick";

interface SalesData {
  Brand: string;
  UnitSold: number;
  Location_ID: string;
}

interface DepartmentSalesData {
  Department: string;
  UnitSold: number;
}

// Define the location abbreviations
const locationAbbreviations: { [key: string]: string } = {
  Alliston: "AL",
  Brampton: "BR",
  Cartwright: "CW",
  "New-Scarborough": "SCS",
  Niagara: "NF",
  Scarborough: "SC",
  Pickering: "PC",
  Yorkgate: "YG",
  Vaughan: "V",
  OPM: "OPM",
  Steeles: "ST",
  "OPM-Hamilton": "OHAM",
  "Dufferin-Orfus": "ORFUS",
  Newmarket: "SVP-NM",
  Queensway: "QW",
};

// Define custom styled components
const StyledTableCell = styled(TableCell)({
  fontWeight: "bold", // Make text bold
});

const StyledTableRow = styled(TableRow)({
  "&:nth-of-type(odd)": {
    backgroundColor: "#f5f5f5", // Light grey for odd rows
  },
  "&:hover": {
    backgroundColor: "#e0e0e0", // Slightly darker on hover
  },
});

const SalesByBrand: React.FC = () => {
  // Helper function to format a date to the YYYY-MM-DDT03:00 format
  const formatDateWithTime = (
    date: Date,
    hours: number,
    minutes: number = 0
  ) => {
    const year = date.getFullYear();
    const month = `0${date.getMonth() + 1}`.slice(-2); // Ensure two digits
    const day = `0${date.getDate()}`.slice(-2); // Ensure two digits
    return `${year}-${month}-${day}T${`0${hours}`.slice(
      -2
    )}:${`0${minutes}`.slice(-2)}`;
  };

  // Compute the first date of the current month and tomorrow's date
  const getFirstDateOfCurrentMonth = () => {
    const date = new Date();
    date.setDate(1); // Set to first day of the month
    return formatDateWithTime(date, 3); // Default to 03:00 AM
  };

  const getTomorrowDate = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1); // Add one day to get tomorrow
    return formatDateWithTime(tomorrow, 3); // Default to 03:00 AM
  };




  const [dateFilter, setDateFilter] = useState<string>("today");
  const [startDate, setStartDate] = useState<string>(
    getFirstDateOfCurrentMonth()
  );
  const [endDate, setEndDate] = useState<string>(getTomorrowDate());

  const [totalSales, setTotalSales] = useState<number>(0);
  const [brandOfTheDay, setBrandOfTheDay] = useState<string>(""); // For Brand of the Day
  const [brandOfTheDayUnits, setBrandOfTheDayUnits] = useState<number>(0); // For total units sold by Brand of the Day
  const theme = useTheme();
  const isXSmall = useMediaQuery(theme.breakpoints.down("xs"));
  const [chartData, setChartData] = useState<any[]>([]);
  const [topBrands, setTopBrands] = useState<string[]>([]);
  const [selectedStoreData, setSelectedStoreData] = useState<SalesData[] | null>(null);
  const [selectedStore, setSelectedStore] = useState<string | null>(null);

  // New state for expanded rows and department data
  const [expandedBrand, setExpandedBrand] = useState<string | null>(null);
  const [departmentData, setDepartmentData] = useState<{ [key: string]: DepartmentSalesData[] }>({});

  useEffect(() => {
    const fetchAndSetData = async () => {
      const salesApiUrl = `/salesByBrand/salesByLoc?dateFilter=${dateFilter}` +
        (dateFilter === "custom" ? `&startDate=${startDate}&endDate=${endDate}` : "");

      try {
        const salesResponse = await fetch(salesApiUrl);
        const { currentYearsalesData }: { currentYearsalesData: SalesData[] } = await salesResponse.json();

        const locationMap: { [key: string]: any } = {};
        currentYearsalesData.forEach((sale) => {
          const abbreviatedLocation = locationAbbreviations[sale.Location_ID] || sale.Location_ID;
          if (!locationMap[abbreviatedLocation]) {
            locationMap[abbreviatedLocation] = { Location_ID: abbreviatedLocation };
          }
          locationMap[abbreviatedLocation][sale.Brand] = sale.UnitSold;
        });

        const chartDataArray = Object.values(locationMap);

        const brandTotals: { [key: string]: number } = {};
        currentYearsalesData.forEach((sale) => {
          brandTotals[sale.Brand] = (brandTotals[sale.Brand] || 0) + sale.UnitSold;
        });

        const sortedBrands = Object.keys(brandTotals)
          .sort((a, b) => brandTotals[b] - brandTotals[a])
          .slice(0, 5);

        const topBrand = sortedBrands[0];
        setBrandOfTheDay(topBrand);
        setBrandOfTheDayUnits(brandTotals[topBrand]);

        setTopBrands(sortedBrands);
        setChartData(chartDataArray);

        const totalUnits = currentYearsalesData.reduce((acc, curr) => acc + curr.UnitSold, 0);
        setTotalSales(totalUnits);
      } catch (error) {
        console.error("Error fetching sales data:", error);
      }
    };

    fetchAndSetData();
  }, [dateFilter, startDate, endDate]);

  const formatNumber = (value: number | undefined): string => {
    return value !== undefined ? value.toLocaleString() : "0";
  };


    // New useEffect to fetch department data based on selected store and expanded brand
    useEffect(() => {
      const fetchDepartmentData = async () => {
        if (expandedBrand && selectedStore) {
          try {
            const fullStoreName = getFullStoreName(selectedStore);
  
            const depApiUrl = `/salesByBrand/salesByDep?brand=${expandedBrand}&location=${fullStoreName}&dateFilter=${dateFilter}` +
              (dateFilter === "custom" ? `&startDate=${startDate}&endDate=${endDate}` : "");
  
            const depResponse = await fetch(depApiUrl);
            const { departmentSalesData }: { departmentSalesData: DepartmentSalesData[] } = await depResponse.json();
  
            setDepartmentData((prevState) => ({
              ...prevState,
              [expandedBrand]: departmentSalesData,
            }));
          } catch (error) {
            console.error("Error fetching department data:", error);
          }
        }
      };
  
      fetchDepartmentData();
    }, [expandedBrand, selectedStore, dateFilter, startDate, endDate]);

    
    const handleBarClick = (data: any) => {
      const storeData = chartData.find((store) => store.Location_ID === data.Location_ID);
      const selectedData = Object.keys(storeData)
        .filter((key) => key !== "Location_ID")
        .map((brand) => ({
          Brand: brand,
          UnitSold: storeData[brand],
          Location_ID: storeData.Location_ID,
        }));
  
      selectedData.sort((a, b) => b.UnitSold - a.UnitSold);
  
      setSelectedStoreData(selectedData);
      setSelectedStore(storeData.Location_ID); // Ensure the selected store is updated
  
      // Collapse any expanded brand when changing store
      setExpandedBrand(null);
    };
  
    const handleBrandClick = (brand: string) => {
      if (expandedBrand === brand) {
        setExpandedBrand(null); // Collapse if already expanded
      } else {
        setExpandedBrand(brand); // Expand the new row
      }
    };

  // Define the reverse mapping for abbreviations to full names
  const reverseLocationMap: { [key: string]: string } = Object.fromEntries(
    Object.entries(locationAbbreviations).map(([fullName, abbreviation]) => [abbreviation, fullName])
  );
  
  // Function to get full store name based on abbreviation
  const getFullStoreName = (abbreviation: string): string => {
    return reverseLocationMap[abbreviation] || abbreviation; // If not found, return the abbreviation itself
  };

  
  

  const colors = ["#003f5c", "#bc5090", "#58508d", "#ff6361", "#ffa600"];

  return (
    <Box sx={{ flexGrow: 1, padding: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box sx={{ mb: 2 }}>
            <FormControl
              fullWidth
              size={isXSmall ? "small" : "medium"}
              style={{ alignItems: "center" }}
            >
              <Select
                value={dateFilter}
                onChange={(e) => setDateFilter(e.target.value)}
                style={{ height: "25px", width: "40%" }}
              >
                <MenuItem value="today">Today</MenuItem>
                <MenuItem value="yesterday">Yesterday</MenuItem>
                <MenuItem value="custom">Custom Dates</MenuItem>
              </Select>
            </FormControl>
            {dateFilter === "custom" && (
              <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
                <TextField
                  label="startDate"
                  type="datetime-local"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    "& .MuiInputBase-input": {
                      fontSize: "0.675rem",
                    },
                    "& .MuiInputLabel-root": {
                      fontSize: "0.675rem",
                    },
                  }}
                />
                <TextField
                  label="endDate"
                  type="datetime-local"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    "& .MuiInputBase-input": {
                      fontSize: "0.675rem",
                    },
                    "& .MuiInputLabel-root": {
                      fontSize: "0.675rem",
                    },
                  }}
                />
              </Box>
            )}
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
              <Typography
                variant="h6"
                component="h2"
                gutterBottom
                align="center"
                style={{
                  border: "double",
                  margin: "5px",
                  padding: "8px",
                  background: "#3b444b",
                  color: "white",
                  fontWeight: "bolder",
                  fontSize: "18pt",
                }}
              >
                Total Units Sold: {formatNumber(totalSales)}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", mt: 2 }}>
              <Typography
                variant="h6"
                component="h2"
                gutterBottom
                align="center"
                style={{
                  border: "double",
                  margin: "5px",
                  padding: "8px",
                  background: "#1f77b4",
                  color: "white",
                  fontWeight: "bolder",
                  fontSize: "14pt",
                }}
              >
                Brand of the Day: {brandOfTheDay} ({formatNumber(brandOfTheDayUnits)} units)
              </Typography>
            </Box>
          </Box>

          {/* Stacked Bar Chart for top brands by location */}
          {chartData.length > 0 ? (
            <Box>
              <Typography
                variant="subtitle1"
                align="center"
                style={{
                  marginBottom: "8px",
                  color: "#333",
                  fontWeight: "bold",
                }}
              >
                Company's Top 5 Brands:
              </Typography>

              <ResponsiveContainer width="100%" height={280} style={{ fontSize: "8pt", fontWeight: "bold" }}>
                <BarChart data={chartData} margin={{ top: 5, right: 30, left: 20, bottom: 20 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="Location_ID"
                    interval={0}
                    tick={<CustomTick x={0} y={0} payload={undefined} index={0} />}
                    height={isXSmall ? 80 : 30}
                  />
                  <Tooltip formatter={(value: number) => formatNumber(value)} />
                  <Legend layout="horizontal" verticalAlign="top" align="center" />
                  {topBrands.map((brand, index) => (
                    <Bar
                      key={brand}
                      dataKey={brand}
                      barSize={isXSmall ? 14 : 16}
                      stackId="a"
                      fill={colors[index]}
                      onClick={(data) => handleBarClick(data)}
                    />
                  ))}
                </BarChart>
              </ResponsiveContainer>
            </Box>
          ) : (
            <Typography variant="h6" align="center">
              Loading......
            </Typography>
          )}
        </Grid>

        {/* Display selected store details in a table */}
        {selectedStoreData && (
          <Grid item xs={12} md={6}>
            <Box>
              <Typography
                variant="h6"
                gutterBottom
                align="center"
                style={{
                  border: "double",
                  background: "#3b444b",
                  color: "white",
                  fontWeight: "bolder",
                  fontSize: "18pt",
                }}
              >
                {selectedStore ? getFullStoreName(selectedStore) : "Store Name"}
              </Typography>
              <TableContainer component={Paper} style={{ maxHeight: "400px", overflow: "auto" }}>
                <Table stickyHeader>
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell>Brand</StyledTableCell>
                      <StyledTableCell align="center">Unit Sold</StyledTableCell>
                      <StyledTableCell align="center"></StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {selectedStoreData?.map((detail) => (
                      <React.Fragment key={detail.Brand}>
                        <StyledTableRow>
                          <StyledTableCell>{detail.Brand}</StyledTableCell>
                          <StyledTableCell align="center">{formatNumber(detail.UnitSold)}</StyledTableCell>
                          <StyledTableCell align="center">
                            <IconButton onClick={() => handleBrandClick(detail.Brand)}>
                              {expandedBrand === detail.Brand ? <ExpandLess /> : <ExpandMore />}
                            </IconButton>
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <TableCell colSpan={3} style={{ padding: 0 }}>
                            <Collapse in={expandedBrand === detail.Brand} timeout="auto" unmountOnExit>
                              <Box margin={1}>
                                <Table size="small">
                                  <TableHead>
                                    <StyledTableRow>
                                      <StyledTableCell>Department</StyledTableCell>
                                      <StyledTableCell align="center">Units Sold</StyledTableCell>
                                    </StyledTableRow>
                                  </TableHead>
                                  <TableBody>
                                    {departmentData[detail.Brand]?.map((dep) => (
                                      <StyledTableRow key={dep.Department}>
                                        <StyledTableCell>{dep.Department}</StyledTableCell>
                                        <StyledTableCell align="center">{formatNumber(dep.UnitSold)}</StyledTableCell>
                                      </StyledTableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </StyledTableRow>
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default SalesByBrand;


