// Import statements
import React, { useEffect, useState } from 'react';
import { TextField, Toolbar, AppBar, Typography, useMediaQuery, Button, Card, CardContent, Grid, Collapse, Container, Box } from '@mui/material';
//import axios from 'axios';
import NavBar from '../Navigation/Navigation';
import { styled, useTheme } from '@mui/material/styles';
import TableSortLabel from '@mui/material/TableSortLabel/TableSortLabel';
import TableHead from '@mui/material/TableHead/TableHead';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import tableCellClasses from '@mui/material/TableCell/tableCellClasses';
import TableRow from '@mui/material/TableRow/TableRow';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import Paper from '@mui/material/Paper/Paper';
import Table from '@mui/material/Table/Table';
//import Grid from '@mui/material/Grid/Grid';
import _, { debounce } from 'lodash';
//import Input from '@mui/material/Input/Input';
//import TablePagination from '@mui/material/TablePagination/TablePagination';

interface ProductStyle {
  PLU_SKU: string;
  QTY_On_Hand: number;
  Size1_Code: string;
  Location_ID: string;
  Style_Code: string;
  BIN: string;
  MSRP: number;
  Last_Landed_Cost: number;
  TotalAgedDays: number;
  Selling_Price: number;
  Department: string;
  Supplier_Code: string;
  Brand: string;
  Class: string;
  Description_1: string;
  Color_Code: string;
}





const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: "#F9F6EE", // Light color for odd rows
  },
  '&:nth-of-type(even)': {
    backgroundColor: "#FAF9F6", // Slightly different light color for even rows
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));




const InventoryPage: React.FC = () => {
  const [inputValue, setInputValue] = useState<string>('SVP-Woven');
  const [expandedRow, setExpandedRow] = useState<string | null>(null);
  const [data, setData] = useState<ProductStyle[]>([]);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const [sortAsc, setSortAsc] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#FFE8D6',
      color: theme.palette.common.black,
      fontSize: isSmallScreen ? 12 : 16, // Smaller font size on small screens
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: isSmallScreen ? 10 : 14,

      // Smaller font size on small screens
    },
  }));

  const handleRowClick = (identifier: string) => {
    setExpandedRow(expandedRow === identifier ? null : identifier);
  };

  useEffect(() => {
    // Correctly use useEffect here to load the default style data on initial render
    fetchData('SVP-Woven');
  }, []); // Empty dependency array means this effect runs once on mount




  // Function to fetch inventory data based on style

  // Function to fetch inventory data based on style
  const fetchData = async (style: string) => {
    setLoading(true); // Start loading
    try {
      const response = await fetch(`/inventory?style=${encodeURIComponent(style)}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const fetchedData: ProductStyle[] = await response.json();
      setData(fetchedData); // Set fetched data
      sortData();
    } catch (error) {
      console.error('Error fetching data:', error);
      setData([]); // Clear data on error
    } finally {
      setLoading(false); // End loading
    }
  };

  // Debounce search input to reduce the number of requests
  useEffect(() => {
    const debouncedFetch = debounce(() => fetchData(inputValue), 500);

    debouncedFetch();

    // Cleanup function to cancel the debounce if component unmounts or inputValue changes
    return () => debouncedFetch.cancel();
  }, [inputValue]);



  // Custom styled components
  const CustomCard = styled(Card)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper, // Use theme colors for better theming
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Custom shadow for depth
    '&:hover': {
      boxShadow: '0 6px 12px rgba(0, 0, 0, 0.2)', // Enhanced shadow on hover
    },
  }));

  const CustomCardContent = styled(CardContent)({
    textAlign: 'center', // Center align the text
    paddingBottom: '16px !important', // Override padding-bottom
  });

  const CustomTypographyTitle = styled(Typography)({
    color: '#0F2143',
    backgroundColor: "#D3D3D3", // Example: blue color for the title
    fontWeight: 'bold',
  });

  const [expandedStyle, setExpandedStyle] = useState<string | null>(null);

  // Adjust this function to toggle based on a unique identifier composed of styleCode and locationId
  const handleToggleDetails = (uniqueIdentifier: string) => {
    if (expandedStyle === uniqueIdentifier) {
      setExpandedStyle(null); // Collapse if it's already expanded
    } else {
      setExpandedStyle(uniqueIdentifier); // Expand the new item
    }
  };


  // Aggregate data by Style_Code
  const aggregatedData = data.reduce((acc, item) => {
    // Initialize style code grouping if not already present
    if (!acc[item.Style_Code]) {
      acc[item.Style_Code] = {};
    }
    // Initialize location grouping within the style code if not already present
    if (!acc[item.Style_Code][item.Location_ID]) {
      acc[item.Style_Code][item.Location_ID] = [];
    }
    // Add item to the appropriate location grouping within the style code
    acc[item.Style_Code][item.Location_ID].push(item);
    return acc;
  }, {} as Record<string, Record<string, ProductStyle[]>>);

  const sortData = () => {
    setData(prevData =>
      [...prevData].sort((a, b) =>
        sortAsc ? b.Location_ID.localeCompare(a.Location_ID) : a.Location_ID.localeCompare(b.Location_ID)
      )
    );
    setSortAsc(!sortAsc); // Toggle sort order
  };

  useEffect(() => {
    // Initial sort when component mounts
    sortData();
  }, []);

  // Render card view with aggregated data
  const renderCardView = () => {
    return (
      <Grid container spacing={2}>
        {Object.entries(aggregatedData).map(([styleCode, locations], index) => (
          <Grid item xs={12} key={index}>
            {Object.entries(locations).map(([locationId, items], locIndex) => {
              // Find the first item with a BIN, if any, or use "N/A"
              const commonBIN = items.find(item => item.BIN)?.BIN || "N/A";

              return (
                <CustomCard key={`${styleCode}-${locationId}`}>
                  <CustomCardContent>
                    <CustomTypographyTitle variant="h6" onClick={() => handleToggleDetails(`${styleCode}-${locationId}`)} style={{ cursor: 'pointer' }}>
                      {`${styleCode} - ${locationId}`}
                    </CustomTypographyTitle>
                    {/* Use a container Grid for the 2x2 layout */}
                    <Grid container spacing={2}>
                      {/* First pair */}
                      <Grid item xs={6}>
                        <Typography variant="body2">Location:</Typography>
                        <Typography variant="body1">{locationId}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2">OH Qty:</Typography>
                        <Typography variant="body1">{items.reduce((acc, item) => acc + item.QTY_On_Hand, 0)}</Typography>
                      </Grid>
                      {/* Second pair */}
                      <Grid item xs={6}>
                        <Typography variant="body2">Description:</Typography>
                        <Typography variant="body1">{items[0].Description_1}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2">Cost:</Typography>
                        <Typography variant="body1">${items[0].Last_Landed_Cost}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2">MSRP:</Typography>
                        <Typography variant="body1">${items[0].MSRP}</Typography>
                      </Grid>
                      {/* Third pair */}
                      <Grid item xs={6}>
                        <Typography variant="body2">Store Price:</Typography>
                        <Typography variant="body1">${items[0].Selling_Price}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2">Supplier:</Typography>
                        <Typography variant="body1">{items[0].Supplier_Code}</Typography>
                      </Grid>
                      {/* Fourth pair */}
                      <Grid item xs={6}>
                        <Typography variant="body2">Brand:</Typography>
                        <Typography variant="body1">{items[0].Brand}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2">Department:</Typography>
                        <Typography variant="body1">{items[0].Department}</Typography>
                      </Grid>
                      {/* Additional pairs */}
                      <Grid item xs={6}>
                        <Typography variant="body2">Category:</Typography>
                        <Typography variant="body1">{items[0].Class}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2">BIN:</Typography>
                        <Typography variant="body1">{commonBIN}</Typography>
                      </Grid>
                    </Grid>
                    {/* More details can be added similarly */}

                    <Button size="small" onClick={() => handleToggleDetails(`${styleCode}-${locationId}`)}>
                      {expandedStyle === `${styleCode}-${locationId}` ? 'View Less' : 'View More'}
                    </Button>
                    <Collapse in={expandedStyle === `${styleCode}-${locationId}`}>
                      <Table size="small">
                        <TableHead>
                          <TableRow style={{ backgroundColor: '#C0C0C0' }}>
                            <StyledTableCell align='center'>SKU</StyledTableCell>
                            <StyledTableCell align='center'>Size</StyledTableCell>

                            <StyledTableCell align='center'>OH Qty</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {items.map((item, idx) => (
                            <StyledTableRow key={idx}>
                              <StyledTableCell align='center' style={{ fontWeight: 'bolder' }}>{item.PLU_SKU}</StyledTableCell>
                              <StyledTableCell align='center' style={{ fontWeight: 'bolder' }}>{item.Size1_Code}</StyledTableCell>

                              <StyledTableCell align='center' style={{ fontWeight: 'bolder' }}>{item.QTY_On_Hand}</StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Collapse>
                  </CustomCardContent>
                </CustomCard>
              );
            })}
          </Grid>
        ))}
      </Grid>
    );
  };


  // Modification starts here for TableBody
  const renderTableBody = () => {
    return (
      <>
        {Object.entries(aggregatedData).map(([styleCode, locations]) => (
          Object.entries(locations).map(([locationId, items]) => {
            // Determine if any item has a BIN and use the first one found
            const commonBIN = items.find(item => item.BIN)?.BIN || "N/A";

            return (
              <>
                <StyledTableRow key={`${styleCode}-${locationId}`} onClick={() => handleRowClick(`${styleCode}-${locationId}`)} sx={{ cursor: 'pointer' }}>
                  <StyledTableCell>{locationId}</StyledTableCell>
                  <StyledTableCell>{`${styleCode}`}</StyledTableCell>
                  <StyledTableCell>{items[0].Description_1}</StyledTableCell>
                  <StyledTableCell>{items[0].Color_Code}</StyledTableCell>
                  <StyledTableCell>{items.reduce((acc, item) => acc + item.QTY_On_Hand, 0)}</StyledTableCell>
                  <StyledTableCell>${items[0].Last_Landed_Cost}</StyledTableCell>
                  <StyledTableCell>${items[0].MSRP}</StyledTableCell>
                  <StyledTableCell>${items[0].Selling_Price}</StyledTableCell>
                  <StyledTableCell>{items[0].Supplier_Code}</StyledTableCell>
                  <StyledTableCell>{items[0].Brand}</StyledTableCell>
                  <StyledTableCell>{items[0].Department}</StyledTableCell>
                  <StyledTableCell>{items[0].Class}</StyledTableCell>
                  <StyledTableCell>{commonBIN}</StyledTableCell>
                </StyledTableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
                    <Collapse in={expandedRow === `${styleCode}-${locationId}`} timeout="auto" unmountOnExit>
                      <Box margin={1}>
                        <Table size="small" aria-label="details" align='center'>
                          <TableHead>
                            <StyledTableRow>
                              <StyledTableCell>SKU</StyledTableCell>
                              <StyledTableCell>Size</StyledTableCell>
                              <StyledTableCell align="right">OH Qty</StyledTableCell>
                              {/* Add more columns as needed */}
                            </StyledTableRow>
                          </TableHead>
                          <TableBody>
                            {items.map((item, idx) => (
                              <StyledTableRow key={idx}>
                                <StyledTableCell component="th" scope="row">{item.PLU_SKU}</StyledTableCell>
                                <StyledTableCell>{item.Size1_Code}</StyledTableCell>
                                <StyledTableCell align="right">{item.QTY_On_Hand}</StyledTableCell>
                                {/* Fill in more data as needed */}
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </>
            );
          })
        ))}
      </>
    );
  };


  return (
    <>
      <AppBar position="fixed">
       
          <NavBar />
      
      </AppBar>
      <Box sx={{ width: '100%', overflowX: 'auto' }}>
        <Container maxWidth="xl" style={{ marginTop: '64px', marginBottom: '64px', padding: '5px' }}>
          {isSmallScreen ? renderCardView() : (
            <TableContainer component={Paper} style={{ flex: 1, overflowX: 'auto' }}>
              <Table stickyHeader aria-label="sticky table" style={{ minWidth: 750 }}>

                <TableHead>
                  <StyledTableRow>
                    {/* Add Table Headers based on your data */}
                    <StyledTableCell  >{/* Add sort handler to the column header */}
                      <TableSortLabel
                        onClick={sortData} style={{ cursor: 'pointer' }}
                      >
                        Location
                      </TableSortLabel>
                    </StyledTableCell>

                    <StyledTableCell align="center">Style</StyledTableCell>
                    <StyledTableCell align="center">Description</StyledTableCell>

                    <StyledTableCell align="center">Color</StyledTableCell>
                    <StyledTableCell align="center" >
                      <TableSortLabel

                      >
                        OHQty
                      </TableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell align="center">Cost</StyledTableCell>
                    <StyledTableCell align="center">MSRP</StyledTableCell>
                    <StyledTableCell align="center">Store Price</StyledTableCell>
                    <StyledTableCell align="center">Supplier</StyledTableCell>
                    <StyledTableCell align="center">Brand</StyledTableCell>
                    <StyledTableCell align="center">Department</StyledTableCell>
                    <StyledTableCell align="center">Category</StyledTableCell>
                    <StyledTableCell align="center">BIN</StyledTableCell>

                    {/* ... other headers ... */}
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {renderTableBody()}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Container>
        <AppBar position="fixed" color="default" style={{ top: 'auto', bottom: 0 }}>
          <Toolbar>
            <Typography variant='h6' style={{ marginRight: '12px' }}>
              Search:
            </Typography>
            <TextField
              variant="outlined"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              style={{ backgroundColor: 'white', width: '100%' }}
            />
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
};

export default InventoryPage;
