import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
//import axios from 'axios';
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, styled, tableCellClasses, Box, Grid, CircularProgress, useMediaQuery, useTheme, Button } from '@mui/material';
import NavBar from '../Navigation/Navigation';
//import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';


interface InventoryData {
  PLU_SKU: string;
  QTY_On_Hand: number;
  QTY_Received: number;
  QTY_Sold: number;
  Size1_Code: string;
  Location_ID: string;
  Style_Code: string;
  BIN: string;
  Selling_Price: number;
  InTransitQty: number;
  ZoneDescription: string;
}

interface AggregatedData {
  Location_ID: string;
  Total_QTY_On_Hand: number;
  Total_QTY_Received: number;
  TotalInTransitQty : number;
  Total_QTY_Sold: number;
  Common_BIN: string | null;
  Details: InventoryData[];
  Min_QTY_On_Hand: number;
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
    // Grey for odd rows
  },
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.common.white, // White for even rows
  },
  '&:last-child td, &:last-child th': {
    border: 0,

  },
}));

// Define a custom styled component for the blinking button
const BlinkingButton = styled(Button)(({ theme }) => ({
  borderRadius: '20px', // Adjust the border radius for a non-square shape
  fontWeight: 'bold',
  padding: theme.spacing(1, 0.4),
  fontSize: '1rem',
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.common.black,
  animation: 'blinker 1.5s linear infinite', // Apply the blinking animation
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  '@keyframes blinker': {
    '50%': {
      opacity: 0.5,
    },
  },
  textTransform: 'none',
}));


const BarcodeScannerComponent2: React.FC = () => {
  //const [barcode, setBarcode] = useState('');
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const [inventoryData, setInventoryData] = useState<InventoryData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sortAsc, setSortAsc] = useState(false);
  const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set());
  const barcodeRef = useRef('');
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#FFE8D6",
      color: theme.palette.common.black,
      fontSize: isSmallScreen ? 15 : 18, // Smaller font size on small screens
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: isSmallScreen ? 18 : 18,
      // Smaller font size on small screens
    },
  }));

  // Function to aggregate data by location
  const aggregateData = (inventoryData: InventoryData[]): AggregatedData[] => {
    const aggregationMap = new Map<string, AggregatedData>();

    inventoryData.forEach(item => {
      let aggregatedItem = aggregationMap.get(item.Location_ID);

      if (!aggregatedItem) {
        aggregatedItem = {
          Location_ID: item.Location_ID,
          Total_QTY_On_Hand: 0,
          Total_QTY_Received: 0,
          TotalInTransitQty:0,
          Total_QTY_Sold: 0,
          Common_BIN: item.BIN, // Initialize with the first BIN
          Details: [],
          Min_QTY_On_Hand: item.QTY_On_Hand
        };
        aggregationMap.set(item.Location_ID, aggregatedItem);
      }

      aggregatedItem.Total_QTY_On_Hand += item.QTY_On_Hand;
      aggregatedItem.Total_QTY_Received += item.QTY_Received;
      aggregatedItem.Total_QTY_Sold += item.QTY_Sold;
      aggregatedItem.TotalInTransitQty += item.InTransitQty;

      // Check if BIN is the same for all items
      if (item.QTY_On_Hand > aggregatedItem.Min_QTY_On_Hand) {
        aggregatedItem.Common_BIN = item.BIN;
        aggregatedItem.Min_QTY_On_Hand = item.QTY_On_Hand;
      }

      aggregatedItem.Details.push(item);
    });

    return Array.from(aggregationMap.values());
  };



  // useMemo to memoize aggregated data
  const aggregatedInventoryData = useMemo(() => {
    return aggregateData(inventoryData);
  }, [inventoryData]);

  // Function to handle row expansion
  const handleRowExpansion = (locationId: string) => {
    const newExpandedRows = new Set(expandedRows);
    if (expandedRows.has(locationId)) {
      newExpandedRows.delete(locationId);
    } else {
      newExpandedRows.add(locationId);
    }
    setExpandedRows(newExpandedRows);
  };

  const fetchInventoryData = async (barcode: string) => {
    // Reset error state on new fetch attempt
    setError(null);

    if (barcode.trim() === '') {
      setError("Please Scan the right SKU");
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    try {
      const response = await fetch(`/userBin/${barcode}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error("Please Scan the right SKU"); // Use a more specific error message if possible
      }

      const data = await response.json() as InventoryData[];
      if (data.length === 0) { // Assuming the API returns an empty array if no items are found
        throw new Error("Please Scan the right SKU");
      }
      //console.log("Fetched data:", data);
      setInventoryData(data);
      sortData();
    } catch (error) {
      console.error('Error fetching inventory data:', error);
      setError("Please Scan the right SKU");
      setInventoryData([]);
    } finally {
      setIsLoading(false);
    }
  };


  // Debounced function to fetch data
  const fetchInventoryDataDebounced = useCallback(_.debounce(async (barcode: string) => {
    if (barcode.trim() !== '') {
      await fetchInventoryData(barcode.trim());
    }
  }, 450), [fetchInventoryData]); // Add necessary dependencies here


  // Effect for handling keypress events
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        fetchInventoryDataDebounced(barcodeRef.current);
        barcodeRef.current = ''; // Reset the barcode after fetching
      } else {
        barcodeRef.current += event.key; // Update barcode with each keypress
      }
    };

    window.addEventListener('keypress', handleKeyPress);
    return () => {
      window.removeEventListener('keypress', handleKeyPress);
    };
  }, [fetchInventoryDataDebounced]);


  const sortData = () => {
    setInventoryData(prevData =>
      [...prevData].sort((a, b) =>
        sortAsc ? b.Location_ID.localeCompare(a.Location_ID) : a.Location_ID.localeCompare(b.Location_ID)
      )
    );
    setSortAsc(!sortAsc); // Toggle sort order
  };

  useEffect(() => {
    // Initial sort when component mounts
    sortData();
  }, []);

  const styleNumberRow = inventoryData.length > 0 ? (
    <TableRow style={{ backgroundColor: '#F9F6EE' }}>
      <TableCell colSpan={6} align="center" style={{ fontSize: 18, fontWeight: 'bold' }}>
      {inventoryData[0].Style_Code} / StorePrice - ${inventoryData[0].Selling_Price}
      </TableCell>
    </TableRow>
  ) : null;



  return (
    <>
      <NavBar />
      <Box sx={{ width: '100%', overflowX: 'auto' }}>
        {error && (
          <Box textAlign="center" color="red">
            {error}
          </Box>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={8} lg={6} xl={4}>
            {isLoading ? <Grid item xs={10} padding={15}><CircularProgress color="secondary" variant="indeterminate" /> </Grid> : (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 300 }} size="small" aria-label="a dense table">
                  <TableHead style={{ backgroundColor: '#F5F5DC' }}>
                    <TableRow>
                      <StyledTableCell onClick={sortData} style={{ cursor: 'pointer' }}>Location</StyledTableCell>

                      <StyledTableCell align="center">OHQty</StyledTableCell>
                      <StyledTableCell align="center">BIN</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {styleNumberRow}
                    {aggregatedInventoryData.map(aggregatedItem => (
                      <>
                        <StyledTableRow key={aggregatedItem.Location_ID} onClick={() => handleRowExpansion(aggregatedItem.Location_ID)}>
                          <StyledTableCell style={{ fontWeight: 'bolder' }}>{aggregatedItem.Location_ID}</StyledTableCell>
                          <StyledTableCell align="center" style={{ fontWeight: 'bolder' }}>{aggregatedItem.Total_QTY_On_Hand}</StyledTableCell>
                          <StyledTableCell align="center" style={{ fontWeight: 'bolder' }}>
                            {aggregatedItem.Common_BIN ? aggregatedItem.Common_BIN : "N/A"}
                          </StyledTableCell>

                        </StyledTableRow>
                        {expandedRows.has(aggregatedItem.Location_ID) && (
                          <TableRow key={`details-${aggregatedItem.Location_ID}`}>
                            <TableCell colSpan={6}>
                              <Table size="small">
                                <TableHead>
                                  <StyledTableRow style={{ backgroundColor: '#C0C0C0' }}>
                                    <StyledTableCell align='center'>SKU</StyledTableCell>
                                    <StyledTableCell align='center'>Size</StyledTableCell>
                                    <StyledTableCell align='center'>OHQty</StyledTableCell>
                                    <StyledTableCell align='center'>InTransitQty</StyledTableCell>
                                    <StyledTableCell align='center'>BIN</StyledTableCell>
                                  </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                  {aggregatedItem.Details.map(detail => (
                                    <StyledTableRow key={detail.PLU_SKU}>
                                      <StyledTableCell align='center' style={{ fontWeight: 'bolder' }}>{detail.PLU_SKU}</StyledTableCell>
                                      <StyledTableCell align='center' style={{ fontWeight: 'bolder' }}>{detail.Size1_Code}</StyledTableCell>
                                      <StyledTableCell align='center' style={{ fontWeight: 'bolder' }}>{detail.QTY_On_Hand}</StyledTableCell>
                                      <StyledTableCell align='center' style={{fontWeight: 'bolder'}}>{detail.InTransitQty}</StyledTableCell>
                                      <StyledTableCell align='center' style={{fontWeight: 'bolder'}}>{detail.BIN}</StyledTableCell>
                                    </StyledTableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="center" m={4}>
          <BlinkingButton onClick={() => navigate('/pickMode')}>
            PickMode
          </BlinkingButton>
        </Box>
      </Box>
    </>
  );
};

export default BarcodeScannerComponent2;