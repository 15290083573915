import React, { useState, useEffect, useMemo } from 'react';
import { DataTable } from 'mantine-datatable';
import { Box, Button, SegmentedControl, Select, TextInput, useMantineTheme } from '@mantine/core';
import dayjs from 'dayjs';
import { IconSearch } from '@tabler/icons-react';
import { DatePicker } from '@mantine/dates';
import { AppBar } from '@mui/material';
import NavBar from '../Navigation/Navigation';

interface TransferDetail {
    receivingLocation: string;
    SendingLocation: string;
    Transfer_No: string;
    Style_Code: string;
    PLU_SKU: string;
    Description_1: string;
    List_Notes: string;
    QTY_Send: number;
    QTY_Received: number;
    Size1_Code: string;
    Discrepancy: number;
    Status: string;
    Send_Date: Date;
    Received_Date: Date;
}

interface AggregatedData {
    SendingLocation: string;
    receivingLocation: string;
    Transfer_No: string;
    QTY_Send: number;
    Status: string;
    Send_Date: Date;
    List_Notes: string;
    Received_Date: Date;
    QTY_Received: number;
    Discrepancy: number;
    Details: TransferDetail[]; // Details for the expanded row
}

const abbreviateLocation = (location: string) => {
    const abbreviations: Record<string, string> = {
        'Steeles': 'ST',
        'Web': 'Web',
        'Vaughan' : 'V',
        'OPM' : 'OPM',
        'Niagara': 'NF',
        'Alliston' : 'AL',
        'Scarborough' : 'SC',
        'Cartwright' : 'CW',
        'Brampton' : 'BR',
        'Pickering' : 'PC',
        'Yorkgate' :  'YG',
        'OPM-Hamilton' : 'OHAM',
        'New-Scarborough' : 'SCS',
        'DC-Caledonia' : 'DC-Cal',
        'DC' : 'DC',
        'OPM-Online' : 'OPM-Online'
        // Add more mappings as necessary
    };
    return abbreviations[location] || location; // Default to the full name if no abbreviation exists
};


const PAGE_SIZES = [10, 15, 20];
const Transfers: React.FC = () => {
    const [data, setData] = useState<TransferDetail[]>([]);

    const [pageSize, setPageSize] = useState(PAGE_SIZES[1]);

    const [page, setPage] = useState(1);


    const [expandedTransferNo, setExpandedTransferNo] = useState<string | null>(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [noteQuery, setNoteQuery] = useState('');
    const [trQuery, setTRQuery] = useState('');
    // State for date filtering
    const [dateFilterMode, setDateFilterMode] = useState<'single' | 'range'>('range');
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [selectedDateRange, setSelectedDateRange] = useState<[Date | null, Date | null]>([null, null]);
    const [statusFilter, setStatusFilter] = useState<string | null>(null);
    const theme = useMantineTheme();
    const [filtersActive, setFiltersActive] = useState<boolean>(false);
    
    const getIsMobile = () => window.innerWidth < parseInt(theme.breakpoints.sm, 10);
    const [isMobile, setIsMobile] = useState<boolean>(getIsMobile());

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(getIsMobile());
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, [theme.breakpoints.sm]); 
    // Reset error state on new fetch attempt

    const handleResetDateFilter = () => {
        setSelectedDate(null);
        setSelectedDateRange([null, null]);
        setDateFilterMode('range'); // Reset to default mode
    };

    const checkFiltersActive = () => {
        return !!selectedDate || !!selectedDateRange[0] || !!selectedDateRange[1] || !!statusFilter;
    };

    useEffect(() => {
        setFiltersActive(checkFiltersActive());
    }, [selectedDate, selectedDateRange, statusFilter]);

    const fetchData = async () => {
        try {
            const response = await fetch(`/trTracking/`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });
            if (!response.ok) throw new Error('Network response was not ok');
            const result = await response.json() as TransferDetail[];
            const abbreviatedData = result.map(item => ({
                ...item,
                SendingLocation: abbreviateLocation(item.SendingLocation),
                receivingLocation: abbreviateLocation(item.receivingLocation)
            }));
            setData(abbreviatedData);
        } catch (error) {
            console.error('Error fetching Transfer Details:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const aggregatedData = useMemo(() => {
        const trimmedSearchQuery = searchQuery.trim().toLowerCase();
        const trimmednoteQuery = noteQuery.trim().toLowerCase();
        const trimmedTRQuery = trQuery.trim();
        const filteredData = data.filter(item => {
            const notes = item.List_Notes || '';
            const sendDate = dayjs(item.Send_Date);

            let dateMatch = true;

            if (dateFilterMode === 'single' && selectedDate) {
                dateMatch = sendDate.isSame(selectedDate, 'day');
            } else if (dateFilterMode === 'range' && selectedDateRange[0] && selectedDateRange[1]) {
                dateMatch = sendDate.isAfter(selectedDateRange[0], 'day') && sendDate.isBefore(selectedDateRange[1], 'day');
            }
            const statusMatch = !statusFilter || item.Status === statusFilter;
            const transferNo = item.Transfer_No.toString();
            return item.receivingLocation.toLowerCase().includes(trimmedSearchQuery) &&
                notes.toLowerCase().includes(trimmednoteQuery) &&
                transferNo.includes(trimmedTRQuery) &&
                dateMatch &&
                statusMatch;
        });

        const aggregationMap = new Map<string, AggregatedData & { uniqueStyles: number, discrepancyStyles: number, transferAccuracy?: number }>();

        filteredData.forEach(item => {
            const key = item.Transfer_No;
            let aggregatedItem = aggregationMap.get(key);

            if (!aggregatedItem) {
                aggregatedItem = {
                    SendingLocation: item.SendingLocation,
                    receivingLocation: item.receivingLocation,
                    Transfer_No: item.Transfer_No,
                    QTY_Send: 0,
                    QTY_Received: 0,
                    Discrepancy: 0,
                    Status: item.Status,
                    List_Notes: item.List_Notes,
                    Send_Date: item.Send_Date,
                    Received_Date: item.Received_Date,
                    Details: [],
                    uniqueStyles: 0,
                    discrepancyStyles: 0
                };
            }

            aggregatedItem.QTY_Send += item.QTY_Send;
            aggregatedItem.QTY_Received += item.QTY_Received;
            aggregatedItem.Discrepancy += item.Discrepancy;
            aggregatedItem.Details.push(item);

            const uniqueStylesSet = new Set(aggregatedItem.Details.map(detail => detail.Style_Code));
            aggregatedItem.uniqueStyles = uniqueStylesSet.size;

            const discrepancyStylesCount = aggregatedItem.Details.filter(detail => detail.Discrepancy > 0).length;
            aggregatedItem.discrepancyStyles = discrepancyStylesCount;

            if (aggregatedItem.Status === 'Received') {
                aggregatedItem.transferAccuracy = ((aggregatedItem.uniqueStyles - aggregatedItem.discrepancyStyles) / aggregatedItem.uniqueStyles) * 100;
            } else if (aggregatedItem.Status === 'Sent') {
                aggregatedItem.transferAccuracy = (0)
            } 

            aggregationMap.set(key, aggregatedItem);
        });

        return Array.from(aggregationMap.values());
    }, [data, searchQuery, noteQuery, trQuery, selectedDate, selectedDateRange, dateFilterMode, statusFilter]);


    const toggleRow = (Transfer_No: string) => {
        setExpandedTransferNo(prev => (prev === Transfer_No ? null : Transfer_No));
    };

      // Add a useEffect hook to reset page number when filters change
      useEffect(() => {
        setPage(1);  // Reset to the first page
    }, [searchQuery, noteQuery, trQuery, selectedDate, selectedDateRange, statusFilter, dateFilterMode]);

       // Function to reset all filters
       const resetFilters = () => {
        setSearchQuery('');
        setNoteQuery('');
        setTRQuery('');
        setSelectedDate(null);
        setSelectedDateRange([null, null]);
        setStatusFilter(null);
        setDateFilterMode('range');
        setPage(1);  // Reset to the first page as well
    };

    return (
        <>
            <AppBar position='sticky'>
                <NavBar />
            </AppBar>
            <Box sx={{ maxWidth: '100%', overflowX: 'auto', height: 'auto' }}>
           
                <DataTable
                    withColumnBorders
                    //striped  // For alternating row colors
                    scrollAreaProps={{ type: 'never' }}
                    withBorder
                    records={aggregatedData.slice((page - 1) * pageSize, page * pageSize)}
                    columns={[
                        { accessor: 'SendingLocation', title: 'From', width: isMobile ? '50' : '100'},
                        {
                            accessor: 'receivingLocation', title: 'TO',  
                            filter: (
                                <TextInput
                                    placeholder="Search..."
                                    icon={<IconSearch size={16} />}
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.currentTarget.value)}
                                />
                            )
                        },
                        {
                            accessor: 'Transfer_No', title: 'TR #No',width: isMobile ? '50' : '100',
                            filter: (
                                <TextInput
                                    placeholder="Search..."
                                    icon={<IconSearch size={16} />}
                                    value={trQuery}
                                    onChange={(e) => setTRQuery(e.currentTarget.value)}
                                />
                            )
                        },
                        { accessor: 'QTY_Send', title: 'QtySend' },
                        { accessor: 'QTY_Received', title: 'QtyRCV' },
                        { accessor: 'Discrepancy', title: 'Discrepancy'},
                        { accessor: 'uniqueStyles', title: '#Styles'},
                         //{ accessor: 'discrepancyStyles', title: '#Styles Discrepancy'},
                        // {
                        //     accessor: 'transferAccuracy',
                        //     title: 'TR Accuracy(%)',
                        //     //width: 100, 
                        //     render: record => `${record.transferAccuracy?.toFixed(2)}%`
                        // },
                        {
                            accessor: 'Status', title: 'Status',
                            filter: (
                                <Select
                                    placeholder="Filter by status"
                                    data={[
                                        { value: 'Received', label: 'Received' },
                                        { value: 'Sent', label: 'Sent' },
                                        // Add more status options as needed
                                    ]}
                                    value={statusFilter}
                                    onChange={setStatusFilter}
                                    clearable
                                />
                            )
                        }, 
                        {
                            accessor: 'Send_Date',width: isMobile ? '50' : '100',
                            title: 'SendDate',  render: record => dayjs(record.Send_Date).format('YYYY-MM-DD'),
                            filter: (
                                <Box>
                                    <SegmentedControl
                                        value={dateFilterMode}
                                        onChange={(value) => setDateFilterMode(value as 'single' || 'range')}
                                        data={[
                                            { label: 'Single Date', value: 'single' },
                                            { label: 'Date Range', value: 'range' }
                                        ]}
                                    />
                                    {dateFilterMode !== 'single' ? (
                                        <DatePicker
                                            value={selectedDateRange}
                                            onChange={(range: [Date, Date]) => setSelectedDateRange(range)}
                                            type="range"
                                        />
                                    ) : (
                                        <DatePicker
                                            value={selectedDate}
                                            onChange={setSelectedDate}
                                        />
                                    )}
                                    <Button onClick={handleResetDateFilter} size="xs">
                                        Reset
                                    </Button>
                                </Box>
                            )
                        },

                        {
                            accessor: 'List_Notes', title: 'Notes',width: isMobile ? '50' : '100',
                            filter: (
                                <TextInput
                                    placeholder="Search..."
                                    icon={<IconSearch size={16} />}
                                    value={noteQuery}
                                    onChange={(e) => setNoteQuery(e.currentTarget.value)}
                                />
                            )
                        }
                        
                    ]}


                     
                    onRowClick={(record) => toggleRow(record.Transfer_No)}
                    rowExpansion={{
                        allowMultiple: false,
                        content: ({ record }) => {
                            // Check if the record is the one expanded and has a discrepancy
                            if (record.Transfer_No === expandedTransferNo && record.Discrepancy !== 0) {
                                return (
                                    <Box sx={{ maxWidth: '80%', overflowX: 'auto' }}>
                                        <DataTable
                                            records={record.Details.filter((detail) => detail.Transfer_No === record.Transfer_No && detail.Discrepancy !== 0)}
                                            columns={[
                                                { accessor: 'Style_Code', title: 'Style No' },
                                                { accessor: 'PLU_SKU', title: 'PLU SKU', width: 100 },
                                                { accessor: 'Description_1', title: 'Desc' },
                                                { accessor: 'Size1_Code', title: 'Size', width: 100 },
                                                { accessor: 'QTY_Send', title: 'QtySend', width: 100 },
                                                { accessor: 'QTY_Received', title: 'Qty RCV', width: 100 },
                                                { accessor: 'Discrepancy', title: 'Discrepancy' }
                                            ]}
                                        />
                                    </Box>
                                );
                            } else if (record.Transfer_No === expandedTransferNo && record.Discrepancy === 0) {
                                // Return a message if the discrepancy is zero for the expanded row
                                return <Box sx={{ padding: '10px', backgroundColor: '#8cc751', textAlign: 'center', fontWeight: 'bolder' }}>No discrepancies for this transfer.</Box>;
                            } else if (record.Transfer_No === expandedTransferNo && record.Status === 'Sent'){
                                return <Box sx={{ padding: '10px', backgroundColor: '#8cc751', textAlign: 'center', fontWeight: 'bolder' }}>Transfer Not Received Yet...</Box>;
                            }
                            return null; // Return null if no condition is met
                        }
                    }}
                    
                    totalRecords={aggregatedData.length}
                    paginationColor="grape"
                    recordsPerPage={pageSize}
                    page={page}
                    onPageChange={(p) => setPage(p)}
                    recordsPerPageOptions={PAGE_SIZES}
                    onRecordsPerPageChange={setPageSize}
                    sx={{
                        th: {
                            backgroundColor: theme.colors.yellow[2], // Header background color
                            position: 'sticky',
                            top: 0,
                            zIndex: 1
                        },
                        'tbody tr:nth-of-type(odd)': {
                            backgroundColor: theme.colors.gray[1],
                            whiteSpace: 'nowrap' // Alternating row color
                        },
                        'tbody tr:nth-of-type(even)': {
                            backgroundColor: theme.colors.dark[0] ,
                            whiteSpace: 'nowrap'
                        },
                        ...(isMobile && {
                            'td, th': {
                                whiteSpace: 'nowrap'
                            }
                        })
                    }}
                    
                />
            </Box>
            <Box display="flex"  sx={{ marginBottom: '10px', justifyContent: 'center' }}>
                    {/* Reset button placed in the line of the DataTable header */}
                    <Button onClick={resetFilters} variant="light" size="sm" compact>
                        Reset All Filters
                    </Button>
                </Box>
        </>
    );
};

export default Transfers;
