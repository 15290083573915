// GlobalInventory.tsx
import React from 'react';
import { Box, Typography, styled } from '@mui/material';
import NavBar from '../Navigation/Navigation';

const StyledBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f5f5f5',
    color: '#333',
    textAlign: 'center',
    gap: '20px', // Adjust the gap between elements
});

const ComingSoonText = styled(Typography)({
    fontWeight: 'bold',
    fontSize: '2rem',
    letterSpacing: '2px',
    color: '#1976d2', // Adjust the text color
});

const DescriptionText = styled(Typography)({
    fontSize: '1.2rem',
    color: '#555', // Adjust the description text color
});

const GlobalInventory: React.FC = () => {
    return (
        <>
            <NavBar/>
            <StyledBox>
                <ComingSoonText variant="h4">Coming Soon</ComingSoonText>
                <DescriptionText>This feature is under development. Stay tuned!</DescriptionText>
            </StyledBox>
        </>
    );
};

export default GlobalInventory;
