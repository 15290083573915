import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, AppBar, Toolbar, useTheme, useMediaQuery, Container, Box, Card, CardContent, Grid, Typography, styled, tableCellClasses } from '@mui/material';
import NavBar from '../Navigation/Navigation';

interface User {
  _id: string;
  email: string;
  first_name: string;
  last_name: string;
  scope: string;
  location: string;
}

const UsersTable: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [editData, setEditData] = useState<User | null>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [searchText, setSearchText] = useState('');
  const theme = useTheme();
  const isXSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#FFE8D6',
      color: theme.palette.common.black,
      fontSize: isXSmall ? 12 : 16, // Smaller font size on small screens
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: isXSmall ? 10 : 14,

      // Smaller font size on small screens
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: "#F9F6EE", // Light color for odd rows
    },
    '&:nth-of-type(even)': {
      backgroundColor: "#FAF9F6", // Slightly different light color for even rows
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  // Fetch users
  useEffect(() => {

    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await fetch('/auth/users', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data: User[] = await response.json();
      setUsers(data);
    } catch (error) {
      console.error('Failed to fetch users:', error);
    }
  };

  useEffect(() => {
    const lowercasedFilter = searchText.toLowerCase();
    const filteredData = users.filter(user => {
      // Update this part to safely access properties
      return `${user.email} ${user.first_name} ${user.last_name} ${user.scope} ${user.location}`.toLowerCase().includes(lowercasedFilter);
    });
    setFilteredUsers(filteredData);
  }, [searchText, users]);

  const handleOpenDialog = (user: User) => {
    setEditData(user);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSaveChanges = async () => {
    if (!editData) return;

    try {
      const response = await fetch(`/auth/updates/${editData._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(editData),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      // Update local state to reflect the changes
      const updatedUsers = users.map(user => user._id === editData._id ? { ...editData } : user);
      setUsers(updatedUsers);
      setOpenDialog(false);
    } catch (error) {
      console.error('Failed to update user:', error);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditData(prev => prev ? { ...prev, [name]: value } : null);
  };

  // User Cards for mobile view
  const UserCards = filteredUsers.map((user) => (
    <Grid item xs={12} sm={6} md={4} key={user._id}>
      <Card>
        <CardContent>
          <Typography variant="h6">{`${user.first_name} ${user.last_name}`}</Typography>
          <Typography>{`Email: ${user.email}`}</Typography>
          <Typography>{`Scope: ${user.scope}`}</Typography>
          <Typography>{`Location: ${user.location}`}</Typography>
          <Button size="small" onClick={() => handleOpenDialog(user)}>Edit</Button>
        </CardContent>
      </Card>
    </Grid>
  ));

  return (
    <>
      <AppBar position='fixed'>
        <Toolbar>
          <NavBar />
        </Toolbar>
      </AppBar>
      <Container style={{ marginTop: '80px', maxWidth: '100%' }}>

        {isXSmall ? (
          <Grid container spacing={2}>
            {UserCards}
          </Grid>
        ) : (
          <TableContainer component={Paper} >
            <Table>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Email</StyledTableCell>
                  <StyledTableCell>FirstName</StyledTableCell>
                  <StyledTableCell>LastName</StyledTableCell>
                  <StyledTableCell>Scope</StyledTableCell>
                  <StyledTableCell>Location</StyledTableCell>
                  <StyledTableCell>Actions</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {filteredUsers.map((user) => (
                  <StyledTableRow key={user._id}>
                    <StyledTableCell>{user.email}</StyledTableCell>
                    <StyledTableCell>{user.first_name}</StyledTableCell>
                    <StyledTableCell>{user.last_name}</StyledTableCell>
                    <StyledTableCell>{user.scope}</StyledTableCell>
                    <StyledTableCell>{user.location}</StyledTableCell>
                    <StyledTableCell>
                      <Button onClick={() => handleOpenDialog(user)}>Edit</Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>Edit User</DialogTitle>
          <DialogContent>
            {editData && (
              <>
                <TextField autoFocus margin="dense" name="email" label="Email" type="email" fullWidth variant="standard" value={editData.email} onChange={handleChange} />
                <TextField margin="dense" name="first_name" label="First Name" type="text" fullWidth variant="standard" value={editData.first_name} onChange={handleChange} />
                <TextField margin="dense" name="last_name" label="Last Name" type="text" fullWidth variant="standard" value={editData.last_name} onChange={handleChange} />
                <TextField margin="dense" name="scope" label="Scope" type="text" fullWidth variant="standard" value={editData.scope} onChange={handleChange} />
                <TextField margin="dense" name="location" label="Location" type="text" fullWidth variant="standard" value={editData.location} onChange={handleChange} />
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Cancel</Button>
            <Button onClick={handleSaveChanges}>Save</Button>
          </DialogActions>
        </Dialog>
        <AppBar position="fixed" color="default" style={{ top: 'auto', bottom: 0 }}>
          <Toolbar>
            <Typography variant='h6' style={{ marginRight: '12px' }}>
              Search:
            </Typography>
            <TextField
              variant="outlined"
              placeholder="Search Users..."
              onChange={(e) => setSearchText(e.target.value)}
              style={{ backgroundColor: 'white', width: '100%' }}
            />
          </Toolbar>
        </AppBar>
      </Container>
    </>
  );
};

export default UsersTable;
