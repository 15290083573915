import { Route, Routes } from 'react-router-dom';


import createTheme from '@mui/material/styles/createTheme';
import ThemeProvider from '@mui/material/styles/ThemeProvider';

import { createContext, useEffect, useState } from 'react';
import LoginProfilePage from './Authentication/LoginProfilePage';
import RegisterPage from './Authentication/RegisterPage';


import PrivateRoute from './Authentication/PrivateRoute';

import StylePerformance from './Reports/StylePerformance';
import BarcodeScannerComponent2 from './Scanner/UserBin';
import BarcodeScannerComponent from './Scanner/ManagerScanner';
import { RefreshContext } from './Authentication/RefreshContext';
import InventoryPage from './Inventory/Inventory';
import UsersTable from './Authentication/userList';
import BarcodeScannerComponentBulk from './Scanner/PickMode';
import HomePage from './Navigation/HomePage';
import ProfilePage from './Authentication/Profile';
import GlobalInventory from './Inventory/GlobalInventory';
import NavBar from './Navigation/Navigation';
import Transfers from './Transfers/trTracking';


const theme = createTheme({
	palette: {
		mode: 'light', // Light theme
		// You can customize the theme colors as needed
	},
});

// Define the shape of the context


// Initialize the UserContext with default values
export const UserContext = createContext<any>({});

function App() {

	const [refresh, setRefresh] = useState(false);
	const [authenticatedUser, setAuthenticatedUser] = useState<IUserSessionInfo>({
		_id: null,
		email: "",
		first_name: "",
		last_name: "",
		scope: "",
		location: "",
	});
	useEffect(() => {
		async function loadUserSession() {
			try {
				let fetchRes = await fetch("/auth/user", {
					method: "POST",
					credentials: "include",
				});
				let response = await fetchRes.json();
				setAuthenticatedUser((val) => response.user);
			} catch (e) {
				console.log(e);
			}
		}
		loadUserSession();
	}, []);


	return (
		<RefreshContext.Provider value={{ refresh, setRefresh }}>
			<UserContext.Provider value={{ authenticatedUser, setAuthenticatedUser }}>

				<ThemeProvider theme={theme}>

					<Routes> 

					<Route path='/' element={<NavBar/>}/>
   						<Route path='/home' element={<PrivateRoute page={<HomePage />} />} />
						<Route path="/user">
							<Route path="login" element={<LoginProfilePage />} />
							<Route path="profile" element={<ProfilePage />} />
							<Route path="register" element={<RegisterPage />} />
						</Route>
						<Route path='/Sinventory' element={<PrivateRoute page={<InventoryPage />} />} />
						<Route path='/globalInventory' element={<PrivateRoute page={<GlobalInventory />} />} />
						<Route path='/style/performance' element={<PrivateRoute page={<StylePerformance />} />} />
						<Route path='/userbin' element={<PrivateRoute page={<BarcodeScannerComponent2 />} />} />
						<Route path='/inventoryScan' element={<PrivateRoute page={<BarcodeScannerComponent />} />} />
						<Route path='/userList' element={<PrivateRoute page={<UsersTable />} />} />
						<Route path='/pickMode' element={<PrivateRoute page={<BarcodeScannerComponentBulk />} />} />
						<Route path='/transferOrders' element={<PrivateRoute page={<Transfers/>} />} />
					</Routes>
				</ThemeProvider>

			</UserContext.Provider>
		</RefreshContext.Provider>
	);
}

export default App;
