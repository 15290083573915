// StyleInventorySearch.tsx
import React, { useState } from 'react';

import { DataGrid, GridSortModel, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { TextField, Button, Box } from '@mui/material';
import NavBar from '../Navigation/Navigation';

interface StyleInventory {
  // Define the fields based on your backend data structure
  TotalSKUQty: number;
  Style_Code: string;
  Location_ID: string;
  InTransitQty: number;
  TransOutQty: number;
  OHAvgCost: number;
  OHRetail: number;
  LTDSoldCost: number;
  LTDSoldQty: number;
  StorePrice: number;
  SoldQty: number;
  SoldRetail: number;
  OHQty: number;
  TotalTransOutQty: number;
  TotalInTransitQty: number;
  AvgOHAvgCost: number;
  TotalAgedDays: number;
  TotalLTDSoldCost: number;
  OHAvgCosts: number;
  PeriodicOHQty: number;
  TotalOHQty: number;
  STPercentage: number;
  TotalSTPercentage: number;
  GMPercentage: number;
  TotalGMPercentage: number;
  TotalOHCost: number;
  TotalOHRetail: number;
}



const StylePerformance: React.FC = () => {
  const [styleCode, setStyleCode] = useState('');
  const [data, setData] = useState<StyleInventory[]>([]);
  const [loading, setLoading] = useState(false);
  // Set default startDate as 30 days back and endDate as current date
  //const [startDate, setStartDate] = useState(formatDate(thirtyDaysBack));
  //const endDate = formatDate(new Date()); // Current date as endDate
  const [error, setError] = useState('');
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'Location_ID',
      sort: 'asc' as const, // Using 'as const' to narrow down the type to 'asc'
    },
  ]);

  // Calculate 30 days back date
  const thirtyDaysBack = new Date();
  thirtyDaysBack.setDate(thirtyDaysBack.getDate() - 30);

  // Format date to 'YYYY-MM-DD'
  const formatDate = (date: Date) => {
    return date.toISOString().split('T')[0];
  };

  // Set default startDate as 30 days back and endDate as current date
  const [startDate, setStartDate] = useState(formatDate(thirtyDaysBack));
  const endDate = formatDate(new Date()); // Current date as endDat 

  const columns: GridColDef[] = [
    { field: 'Location_ID', headerName: 'Location', width: 120, sortable: true },
    {
      field: 'periodicQtyInfo',
      headerName: 'Periodic Qty Sold',
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        const periodicOHQty = params.row.PeriodicOHQty;
        const soldQty = params.row.SoldQty;
        return <span>{`${soldQty} / ${periodicOHQty}`}</span>;
      },
    },

    { field: 'STPercentage', headerName: 'S.T%', width: 90 },
    { field: 'GMPercentage', headerName: 'GM%', width: 90 },
    {
      field: 'TotalOHQtyInfo',
      headerName: 'Total Qty',
      width: 130,
      renderCell: (params: GridRenderCellParams) => {
        const TotalOHQty = params.row.TotalOHQty;
        const LTDSoldQty = params.row.LTDSoldQty;
        return <span>{`${LTDSoldQty} / ${TotalOHQty}`}</span>;
      },
    },
    { field: 'TotalSTPercentage', headerName: 'Total ST%', width: 120 },
    { field: 'TotalGMPercentage', headerName: 'Total GM%', width: 120 },
    { field: 'TotalInTransitQty', headerName: 'InTransitQty', width: 120 },
    { field: 'TotalTransOutQty', headerName: 'TransferOutQty', width: 120 },
    { field: 'TotalSKUQty', headerName: 'OHQty', width: 120 },
    { field: 'OHAvgCosts', headerName: 'OH Cost', width: 120 },
    { field: 'OHRetail', headerName: 'OH Retail', width: 120 },
    { field: 'OHAvgCost', headerName: 'Avg. Cost', width: 120 },
    { field: 'TotalAgedDays', headerName: 'Aged Days', width: 120 }
  ];

  const handleSearch = async () => {
    setLoading(true);
    setError('');
    try {
      // Construct the query parameters string from the params object
      const queryParams = new URLSearchParams({
        styleCode, // Assuming styleCode, startDate, and endDate are defined somewhere in your component
        startDate,
        endDate,
      }).toString();

      // Make the GET request using fetch
      const response = await fetch(`/style?${queryParams}`, {
        method: 'GET',
        credentials: 'include', // Include cookies in the request. Adjust as needed.
        headers: {
          'Content-Type': 'application/json',
        },
      });

      // Check if the response is successful
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // Parse the JSON response
      const data = await response.json();
      setData(data); // Assuming setData is a function to update your component's state
    } catch (error) {
      console.error('Error fetching style inventory data:', error);
      setError('Failed to fetch data. Please try again.');
    }
    setLoading(false);
  };


  const getRowId = (row: StyleInventory) => `${row.Style_Code}-${row.Location_ID}`;

  return (
    <>
      <NavBar />
      <Box sx={{ p: 2 }}>
        <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
          <TextField
            label="Style Code"
            variant="outlined"
            value={styleCode}
            onChange={(e) => setStyleCode(e.target.value)}
          />
          <TextField
            label="Start Date"
            type="date"
            variant="outlined"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
          <Button variant="contained" onClick={handleSearch} disabled={loading}>
            Search
          </Button>
        </Box>
        <Box sx={{ height: 620, width: '100%' }}>
          <DataGrid
            rows={data}
            columns={columns}
            getRowId={getRowId}
            loading={loading}
            sortModel={sortModel}
            onSortModelChange={(model) => setSortModel(model)}
          />
        </Box>
      </Box>
    </>
  );
};

export default StylePerformance;
