import React, { useEffect, useState } from 'react';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';


interface ReportGeneratorDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const StylePerformanceByTR: React.FC<ReportGeneratorDialogProps> = ({ open, setOpen }) => {
  const [transferNumber, setTransferNumber] = useState<string>('');
  const [startDate, setStartDate] = useState<string>(getInitialStartDate());

  function getInitialStartDate(): string {
    const date = new Date();
    date.setDate(date.getDate() - 30);
    return date.toISOString().split('T')[0];
  }

  // Reset form when dialog is opened
  useEffect(() => {
    if (open) {
      setTransferNumber('');
      setStartDate(getInitialStartDate());
    }
  }, [open]);

  const handleClose = () => {
    // Reset the states to their initial values upon closing the dialog
    setTransferNumber('');
    setStartDate(getInitialStartDate());
    setOpen(false);
  };







  const handleGenerateReport = async () => {
    // Close the dialog and perform any additional cleanup defined in handleClose
    handleClose();

    // Open a new window immediately, but it will wait to load the content
    const reportWindow = window.open('', '_blank');
    if (!reportWindow) {
      alert('Failed to open new window. Please check your popup blocker settings.');
      return;
    }
    reportWindow.document.write('Loading report...'); // Provide immediate feedback

    try {
      // Fetch the PDF
      const response = await fetch(`/generate-pdf?TRNo=${transferNumber}&startDate=${startDate}`, {
        method: 'GET',
      });
      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        // Redirect the new window to the blob URL to display the PDF
        reportWindow.location.href = url;
      } else {
        reportWindow.close(); // Close the new window if the fetch failed
        alert('Failed to generate report');
      }
    } catch (error) {
      console.error('Error generating report:', error);
      reportWindow.close(); // Ensure to close the new window in case of error
      alert('An error occurred while generating the report.');
    }
  };




  return (



    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Generate Report</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="transferNumber"
          label="Transfer Number"
          type="text"
          fullWidth
          variant="outlined"
          value={transferNumber}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTransferNumber(e.target.value)}
        />
        <TextField
          margin="dense"
          id="startDate"
          label="Start Date"
          type="date"
          fullWidth
          variant="outlined"
          value={startDate}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setStartDate(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleGenerateReport}>Submit</Button>
      </DialogActions>
    </Dialog>

  );
};

export default StylePerformanceByTR;
