import React, { useState, useEffect } from 'react';
import { AppBar, Grid, Typography } from '@mui/material';

import NavBar from '../Navigation/Navigation'; // Adjust the import path based on your actual file structure
import SalesByLoc from '../Reports/SalesReports';
import SalesByBrand from '../Reports/Brand';

const HomePage: React.FC = () => {
  const [showNote, setShowNote] = useState(true);  // useState is used inside the component

  useEffect(() => {  // useEffect is used inside the component
    const timer = setTimeout(() => {
      setShowNote(false);
    }, 90000); // 90000 milliseconds = 1.5 minutes

    return () => clearTimeout(timer); // Clear the timer if the component unmounts
  }, []);

  return (
    <>
      <AppBar position='sticky'>
        <NavBar />
      </AppBar>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {showNote && (
            <Typography variant="body2" color="textSecondary" style={{ margin: '10px' }}>
              Note: Web Data is 26 hours delayed from Shopify. Accuracy for WEB Data is about 99.99%.
            </Typography>
          )}
          <SalesByLoc />
        </Grid>
        <Grid item xs={12}>
         
         <SalesByBrand />
       </Grid>
      </Grid>
    </>
  );
};

export default HomePage;
