import React, { useState, useEffect } from "react";
import {
  Box,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  Table,
  styled,
} from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Rectangle,
} from "recharts";
import CustomTick from "./customTick";


interface SalesData {
  Location_ID: string;
  Receipts: number;
  UnitSold: number;
  UPT: number;
  AvgSales: number;
  Discount: number;
  NetSales: number;
  Tax: number;
  GrossSales: number;
  originalLocationID: string;
  CostOfSales: number;
  Margin$: number;
  MarginPercent: number;
}

interface SalesDataShopify {
  Location_ID: string;
  Orders: number;
  UnitSold: number;
  UPT: number;
  AvgSales: number;
  NetSales: number;
  GrossSales: number;
  originalLocationID: string;
  Discount: number;
  Returns: number;
}

interface CombinedSalesData {
  currentYear: SalesData[];
  lastYear: SalesData[];
}

const locationAbbreviations: { [key: string]: string } = {
  Alliston: "AL",
  Brampton: "BR",
  Cartwright: "CW",
  "New-Scarborough": "SCS",
  Niagara: "NF",
  Scarborough: "SC",
  Pickering: "PC",
  Yorkgate: "YG",
  Vaughan: "V",
  OPM: "OPM",
  Steeles: "ST",
  "OPM-Hamilton": "OHAM",
  "Dufferin-Orfus": "ORFUS",
  Newmarket: "SVP-NM",
  Queensway: "QW"
};

// Define custom styled components
const StyledTableCell = styled(TableCell)({
  fontWeight: "bold", // Make text bold
});

function parseCurrency(value: string | undefined): number {
  if (!value) return 0; // Return 0 or a default value if input is undefined
  const numericPart = value.replace(/[^\d.-]/g, '');
  return parseFloat(numericPart);
}


const StyledTableRow = styled(TableRow)({
  "&:nth-of-type(odd)": {
    backgroundColor: "#f5f5f5", // Light grey for odd rows
  },
  "&:hover": {
    backgroundColor: "#e0e0e0", // Slightly darker on hover
  },
});

interface ChartDataItem {
  Location_ID: string;
  "TY-NetSales": number;
  "LY-NetSales": number;
}

const SalesReports: React.FC = () => {
  // Helper function to format a date to the YYYY-MM-DDT03:00 format
  const formatDateWithTime = (
    date: Date,
    hours: number,
    minutes: number = 0
  ) => {
    const year = date.getFullYear();
    const month = `0${date.getMonth() + 1}`.slice(-2); // Ensure two digits
    const day = `0${date.getDate()}`.slice(-2); // Ensure two digits
    return `${year}-${month}-${day}T${`0${hours}`.slice(
      -2
    )}:${`0${minutes}`.slice(-2)}`;
  };

  // Compute the first date of the current month and tomorrow's date
  const getFirstDateOfCurrentMonth = () => {
    const date = new Date();
    date.setDate(1); // Set to first day of the month
    return formatDateWithTime(date, 3); // Default to 03:00 AM
  };

  const getTomorrowDate = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1); // Add one day to get tomorrow
    return formatDateWithTime(tomorrow, 3); // Default to 03:00 AM
  };




  const [dateFilter, setDateFilter] = useState<string>("today");
  const [startDate, setStartDate] = useState<string>(
    getFirstDateOfCurrentMonth()
  );
  const [endDate, setEndDate] = useState<string>(getTomorrowDate());

  const [totalSales, setTotalSales] = useState<number>(0);
  const theme = useTheme();
  const isXSmall = useMediaQuery(theme.breakpoints.down("xs"));
  const [selectedLocation, setSelectedLocation] = useState<string | null>(null);
  const [selectedLocationDetails, setSelectedLocationDetails] = useState<{
    currentYear: SalesData | SalesDataShopify | null;
    lastYear: SalesData | SalesDataShopify | null;
  }>({ currentYear: null, lastYear: null });
  

  const [combinedSalesData, setCombinedSalesData] = useState<CombinedSalesData>(
    { currentYear: [], lastYear: [] }
  );
  const [chartData, setChartData] = useState<ChartDataItem[]>([]);
  const [shopifyData, setShopifyData] = useState<any>(null);


  useEffect(() => {
    const fetchAndSetData = async () => {
      const existingSalesApiUrl = `/salesPerformance/salesByLoc?dateFilter=${dateFilter}` +
        (dateFilter === 'custom' ? `&startDate=${startDate}&endDate=${endDate}` : '');

      // For Shopify, adjust the start and end date to cover the full day from 00:00 to 23:59
      let shopifyStartDate = startDate;
      let shopifyEndDate = endDate;

      if (dateFilter === 'custom') {
        // Adjust the start date to begin at 00:00 on the same day
        shopifyStartDate = `${startDate.split('T')[0]}T00:00`;

        // Adjust the end date to end at 23:59 on the day before the selected end date
        // This is because your end date is already the start of the next day at 3 AM
        const endDateObject = new Date(endDate);
        endDateObject.setDate(endDateObject.getDate() - 1);  // Go back one day
        shopifyEndDate = `${endDateObject.toISOString().split('T')[0]}T23:59`;
      }

      const shopifySalesApiUrl = `/web-data?dateFilter=${dateFilter}` +
        (dateFilter === 'custom' ? `&since=${shopifyStartDate}&until=${shopifyEndDate}` : "");

      try {
        const [existingSalesResponse, shopifySalesResponse] = await Promise.all([
          fetch(existingSalesApiUrl),
          fetch(shopifySalesApiUrl)
        ]);

        const [existingSalesData, shopifySalesData] = await Promise.all([
          existingSalesResponse.json(),
          shopifySalesResponse.json()
        ]);


        const formattedCurrentYearData = existingSalesData.currentYearsalesData.map((d: SalesData) => ({
          ...d,
          Location_ID: locationAbbreviations[d.Location_ID] || d.Location_ID,
          originalLocationID: d.Location_ID,
          NetSales: d.NetSales,
        }));

        const formattedLastYearData = existingSalesData.lastYearsalesData.map((d: SalesData) => ({
          ...d,
          Location_ID: locationAbbreviations[d.Location_ID] || d.Location_ID,
          originalLocationID: d.Location_ID,
          NetSales: d.NetSales,
        }));

        const existingChartData = formattedCurrentYearData.map((current: SalesData) => {
          const lastYear = formattedLastYearData.find((last: SalesData) => last.originalLocationID === current.originalLocationID);
          return {
            Location_ID: current.Location_ID,
            'TY-NetSales': current.NetSales,
            'LY-NetSales': lastYear ? lastYear.NetSales : 0,
          };
        });

        const shopifyChartData: ChartDataItem = {
          Location_ID: 'Web',
          'TY-NetSales': parseCurrency(shopifySalesData.firstQuery.TYNet_Sales),
          'LY-NetSales': parseCurrency(shopifySalesData.firstQuery.LYNet_Sales)
        };

        const combinedChartData = [...existingChartData, shopifyChartData];
        setCombinedSalesData({
          currentYear: formattedCurrentYearData,
          lastYear: formattedLastYearData,
        });

        setChartData(combinedChartData);
        setTotalSales(combinedChartData.reduce((acc, curr) => acc + curr['TY-NetSales'], 0));
        setShopifyData(shopifySalesData);
      } catch (error) {
        console.error('Error fetching sales data:', error);
      }
    };
 
    fetchAndSetData();
  }, [dateFilter, startDate, endDate]);







  const handleBarClick = (data: ChartDataItem) => {
    if (data.Location_ID === 'Web' && shopifyData && shopifyData.firstQuery) {
      const shopifyCurrentYearDetails: SalesDataShopify = {
        Location_ID: 'Web',
        Orders: (shopifyData.secondQuery.TYOrders || '0'), // Set appropriate values
        UnitSold: (shopifyData.firstQuery.TYQTY_Sold || '0'),
        UPT: parseFloat(shopifyData.TYUPT || '0'), // Use '0' as fallback
        AvgSales: parseCurrency(shopifyData.secondQuery.TYAvgSales),
        Discount: parseCurrency(shopifyData.firstQuery.TYDiscounts),
        NetSales: parseCurrency(shopifyData.firstQuery.TYNet_Sales),
       Returns: parseCurrency(shopifyData.firstQuery.TYReturns),
        GrossSales: parseCurrency(shopifyData.firstQuery.TYGross_Sales),
        originalLocationID: 'Web',
      };
  
      const shopifyLastYearDetails: SalesDataShopify = {
        Location_ID: 'Web',
        Orders: (shopifyData.secondQuery.LYOrders || '0'), // Set appropriate values
        UnitSold: (shopifyData.firstQuery.LYQTY_Sold || '0'),
        UPT: parseFloat(shopifyData.LYUPT || '0'), // Use '0' as fallback
        AvgSales: parseCurrency(shopifyData.secondQuery.LYAvgSales),
        Discount: parseCurrency(shopifyData.firstQuery.LYDiscounts),
        NetSales: parseCurrency(shopifyData.firstQuery.LYNet_Sales),
       Returns: parseCurrency(shopifyData.firstQuery.LYReturns),
        GrossSales: parseCurrency(shopifyData.firstQuery.LYGross_Sales),
        originalLocationID: 'Web',
      };
  
      setSelectedLocation(data.Location_ID);
      setSelectedLocationDetails({
        currentYear: shopifyCurrentYearDetails,
        lastYear: shopifyLastYearDetails,
      });
    } else {
      const currentYearDetails = combinedSalesData.currentYear.find(
        (d) => d.Location_ID === data.Location_ID
      );
      // Try to find last year details; if not found, use a fallback with zeroes or suitable defaults
      const lastYearDetails = combinedSalesData.lastYear.find(
        (d) => d.Location_ID === data.Location_ID
      ) || {
        Location_ID: data.Location_ID,
        Receipts: 0,
        UnitSold: 0,
        UPT: 0,
        AvgSales: 0,
        Discount: 0,
        NetSales: 0,
        Tax: 0,
        GrossSales: 0,
        originalLocationID: data.Location_ID, // Use the same ID as originalLocationID for consistency
        CostOfSales: 0,
        MarginDollar: 0,
        MarginPercent: 0,
        Margin$: 0, // Make sure to include this property to match the SalesData interface
      };
  
      setSelectedLocation(data.Location_ID); // Assuming Location_ID is enough to identify the selected location
      setSelectedLocationDetails({
        currentYear: currentYearDetails || null,
        lastYear: lastYearDetails,
      });
    }
  };
  

  const formatDollar = (value: number | undefined): string => {
    if (value === undefined) {
      return "$0.00"; // or however you wish to format undefined values
    }
    return value.toLocaleString("en-CA", {
      style: "currency",
      currency: "CAD",
      minimumFractionDigits: 2,
    });
  };
  

// Type guard function to check if the data is SalesData
const prepareCombinedDataForTable = () => {
  const combinedData: Array<{
    field: string;
    currentValue: string | number;
    lastYearValue: string | number;
  }> = [];

  const currentYearDetails = selectedLocationDetails.currentYear;
  const lastYearDetails = selectedLocationDetails.lastYear;

  if (!currentYearDetails || !lastYearDetails) return [];

  // Check if the data is for Shopify or existing sales
  if ('Orders' in currentYearDetails) {
      // Handle Shopify data
      combinedData.push(
          { field: "Orders", currentValue: currentYearDetails.Orders, lastYearValue: (lastYearDetails as SalesDataShopify).Orders },
          { field: "UPT", currentValue: currentYearDetails.UPT, lastYearValue: (lastYearDetails as SalesDataShopify).UPT },
          { field: "QTY Sold", currentValue: currentYearDetails.UnitSold, lastYearValue: (lastYearDetails as SalesDataShopify).UnitSold },
          { field: "Avg Sales", currentValue: formatDollar(currentYearDetails.AvgSales), lastYearValue: formatDollar((lastYearDetails as SalesDataShopify).AvgSales) },
          { field: "Net Sales", currentValue: formatDollar(currentYearDetails.NetSales), lastYearValue: formatDollar((lastYearDetails as SalesDataShopify).NetSales) },
          { field: "Gross Sales", currentValue: formatDollar(currentYearDetails.GrossSales), lastYearValue: formatDollar((lastYearDetails as SalesDataShopify).GrossSales) },
          { field: "Discounts", currentValue: formatDollar(currentYearDetails.Discount), lastYearValue: formatDollar((lastYearDetails as SalesDataShopify).Discount) },
          { field: "Returns", currentValue: formatDollar(currentYearDetails.Returns), lastYearValue: formatDollar((lastYearDetails as SalesDataShopify).Returns) }
         
      );
  } else {
      // Handle existing sales data
      const fields = ["#Receipts", "UnitSold", "UPT", "AvgSales",  "NetSales", "Tax", "GrossSales", "CostOfSales", "Margin$", "MarginPercent"];
      fields.forEach((field) => {
          const currentValue = (currentYearDetails as SalesData)[field as keyof SalesData];
          const lastYearValue = (lastYearDetails as SalesData)[field as keyof SalesData];
          const isMonetary = ["AvgSales", "NetSales", "Tax", "GrossSales", "CostOfSales", "Margin$"].includes(field);

          combinedData.push({
              field,
              currentValue: isMonetary ? formatDollar(currentValue as number) : currentValue,
              lastYearValue: isMonetary ? formatDollar(lastYearValue as number) : lastYearValue
          });
      });
  }

  return combinedData;
};


  return (
    <Box sx={{ flexGrow: 1, padding: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box sx={{ mb: 2 }}>
            <FormControl
              fullWidth
              size={isXSmall ? "small" : "medium"}
              style={{ alignItems: "center" }}
            >
              <Select
                value={dateFilter}
                onChange={(e) => setDateFilter(e.target.value)}
                style={{ height: "25px", width: "40%" }}
              >
                <MenuItem value="today">Today</MenuItem>
                <MenuItem value="yesterday">Yesterday</MenuItem>
                <MenuItem value="custom">Custom Dates</MenuItem>
              </Select>
            </FormControl>
            <Grid item xs={12}></Grid>
            {dateFilter === "custom" && (
              <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
                <TextField
                  label="startDate"
                  type="datetime-local"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    "& .MuiInputBase-input": {
                      fontSize: "0.675rem", // Adjust the font size as needed
                    },
                    "& .MuiInputLabel-root": {
                      fontSize: "0.675rem", // Adjust the label font size as needed
                    },
                  }}
                />
                <TextField
                  label="endDate"
                  type="datetime-local"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    "& .MuiInputBase-input": {
                      fontSize: "0.675rem", // Adjust the font size as needed
                    },
                    "& .MuiInputLabel-root": {
                      fontSize: "0.675rem", // Adjust the label font size as needed
                    },
                  }}
                />
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Typography
                variant="h6"
                component="h2"
                gutterBottom
                align="center"
                style={{
                  border: "double",
                  margin: "5px",
                  padding: "8px",
                  background: "#3b444b",
                  color: "white",
                  fontWeight: "bolder",
                  fontSize: "18pt",
                }}
              >
                Total Sales: {formatDollar(totalSales)}
              </Typography>
            </Box>
          </Box>
          <ResponsiveContainer
            width="100%"
            height={280}
            style={{ fontSize: "8pt", fontWeight: "bold" }}
          >
            <BarChart
              data={chartData}
              margin={{ top: 5, right: 30, left: 20, bottom: 20 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="Location_ID"
                interval={0} // Let the custom tick component decide on rendering
                tick={<CustomTick x={0} y={0} payload={undefined} index={0} />}
                height={isXSmall ? 80 : 20} // Adjust for custom tick rotation
              />
              <YAxis tickFormatter={formatDollar} />
              <Tooltip formatter={(value: number) => formatDollar(value)} />
              <Legend layout="horizontal" align="center" verticalAlign="top" />

              <Bar
                dataKey="LY-NetSales"
                fill="#F28E2B"
                activeBar={<Rectangle fill="#f4b183" stroke="#F28E2B" />}
                barSize={isXSmall ? 10 : 12} // Adjust bar size based on screen size
                onClick={(data: any) => handleBarClick(data)}
                style={{ cursor: "pointer" }}
              />
              <Bar
                dataKey="TY-NetSales"
                fill="#4E79A7"
                activeBar={<Rectangle fill="#75a8d3" stroke="#4E79A7" />}
                barSize={isXSmall ? 10 : 12} // Adjust bar size based on screen size
                onClick={(data: any) => handleBarClick(data)}
                style={{ cursor: "pointer" }}
              />
            </BarChart>
          </ResponsiveContainer>

        </Grid>
        <Grid item xs={12} md={6}>
          {selectedLocationDetails.currentYear &&
            selectedLocationDetails.lastYear && (
              <Box>
                <Typography
                  variant="h6"
                  gutterBottom
                  align="center"
                  style={{
                    border: "double",
                    background: "#3b444b",
                    color: "white",
                    fontWeight: "bolder",
                    fontSize: "18pt",
                  }}
                >
                  {selectedLocationDetails.currentYear.originalLocationID}
                </Typography>
                <TableContainer
                  component={Paper}
                  style={{ maxHeight: "400px", overflow: "auto" }}
                >
                  <Table stickyHeader>
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell align="center">TY</StyledTableCell>
                        <StyledTableCell align="center">LY</StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {prepareCombinedDataForTable().map(
                        ({ field, currentValue, lastYearValue }) => (
                          <StyledTableRow key={field}>
                            <StyledTableCell component="th" scope="row">
                              {field}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {typeof currentValue === "number"
                                ? currentValue
                                : currentValue}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {typeof lastYearValue === "number"
                                ? lastYearValue
                                : lastYearValue}
                            </StyledTableCell>
                          </StyledTableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default SalesReports;