import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, styled, tableCellClasses, Box, Grid, CircularProgress, useMediaQuery, useTheme, Button } from '@mui/material';
import NavBar from '../Navigation/Navigation';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';

interface InventoryData {
  PLU_SKU: string;
  QTY_On_Hand: number;
  QTY_Received: number;
  QTY_Sold: number;
  Size1_Code: string;
  Location_ID: string;
  Style_Code: string;
  BIN: string;
  Selling_Price : number;
  InTransitQty: number;
  ZoneDescription: string;
  scanId?: string; // Optional identifier for associating data with a specific scan
}

// Aggregated data now includes scanId for identifying which scan the aggregation belongs to
interface AggregatedData {
  Location_ID: string;
  Total_QTY_On_Hand: number;
  Total_QTY_Received: number;
  Total_QTY_Sold: number;
  TotalInTransitQty : number;
  Common_BIN: string | null;
  Details: InventoryData[];
  scanId: string;
  Min_QTY_On_Hand: number;
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.common.white,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
})); 

// Define a custom styled component for the blinking button
const BlinkingButton = styled(Button)(({ theme }) => ({
  borderRadius: '20px', // Adjust the border radius for a non-square shape
  fontWeight: 'bold',
  padding: theme.spacing(1, 0.4),
  fontSize: '1rem',
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.common.black,
  animation: 'blinker 1.5s linear infinite', // Apply the blinking animation
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  '@keyframes blinker': {
    '50%': {
      opacity: 0.5,
    },
  },
  textTransform: 'none',
}));

const BarcodeScannerComponentBulk: React.FC = () => {
  const [scans, setScans] = useState<InventoryData[]>([]);
  const barcodeRef = useRef('');
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set());
  const resetTimer = useRef<NodeJS.Timeout>();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#FFE8D6",
      color: theme.palette.common.black,
      fontSize: isSmallScreen ? 15 : 18,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: isSmallScreen ? 18 : 18,
    },
  }));

  const aggregateData = useCallback((scans: InventoryData[]): AggregatedData[] => {
    const aggregationMap = new Map<string, AggregatedData>();

    scans.forEach(scan => {
      const key = scan.scanId!; // Use scanId as the key
      let aggregatedItem = aggregationMap.get(key);

      if (!aggregatedItem) {
        aggregatedItem = {
          Location_ID: scan.Location_ID,
          Total_QTY_On_Hand: 0,
          Total_QTY_Received: 0,
          Total_QTY_Sold: 0,
          TotalInTransitQty:0,
          Common_BIN: scan.BIN,
          Details: [],
          scanId: key, // Store scanId in the aggregated item
          Min_QTY_On_Hand: scan.QTY_On_Hand,
        };
        aggregationMap.set(key, aggregatedItem);
      }

      aggregatedItem.Total_QTY_On_Hand += scan.QTY_On_Hand;
      aggregatedItem.Total_QTY_Received += scan.QTY_Received;
      aggregatedItem.Total_QTY_Sold += scan.QTY_Sold;
      aggregatedItem.Total_QTY_On_Hand += scan.InTransitQty;
      // Check if BIN is the same for all items
      if (scan.QTY_On_Hand > aggregatedItem.Min_QTY_On_Hand) {
        aggregatedItem.Common_BIN = scan.BIN;
        aggregatedItem.Min_QTY_On_Hand = scan.QTY_On_Hand;
      }

      aggregatedItem.Details.push(scan);
    });

    return Array.from(aggregationMap.values());
  }, []);

  // Memoize aggregated data calculation
  const aggregatedInventoryData = useMemo(() => aggregateData(scans), [scans, aggregateData]);

  // Fetch inventory data for a single barcode, including scanId for aggregation
  const fetchInventoryData = useCallback(async (barcode: string) => {
    setIsLoading(true);
    try {
      const response = await fetch(`/userBin/pick/${barcode}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });
      if (!response.ok) throw new Error('Failed to fetch inventory data');
      const newData = await response.json() as InventoryData[];
      const scanId = new Date().toISOString(); // Use the current timestamp as a unique identifier
      setScans(prev => [...prev, ...newData.map(data => ({ ...data, scanId }))]); // Append new data with scanId
    } catch (error) {
      console.error('Error fetching inventory data:', error);
      setError('Failed to fetch inventory data');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && barcodeRef.current !== '') {
        fetchInventoryData(barcodeRef.current);
        barcodeRef.current = ''; // Reset after fetching
      } else {
        barcodeRef.current += event.key; // Accumulate key presses
      }
    };

    window.addEventListener('keypress', handleKeyPress);
    return () => window.removeEventListener('keypress', handleKeyPress);
  }, [fetchInventoryData]);

  // Handle row expansion for displaying detailed scan data
  const handleRowExpansion = (scanId: string) => {
    const newExpandedRows = new Set(expandedRows);
    if (newExpandedRows.has(scanId)) {
      newExpandedRows.delete(scanId);
    } else {
      newExpandedRows.add(scanId);
    }
    setExpandedRows(newExpandedRows);
  };

  // Function to reset component state
  const resetState = useCallback(() => {
    setScans([]);
    setIsLoading(false);
    setError(null);
    setExpandedRows(new Set());
    // Any other state reset actions
  }, []);

  // Effect to set up the reset timer
  useEffect(() => {
    // Clear existing timer (if any) to prevent multiple timers running
    if (resetTimer.current) {
      clearTimeout(resetTimer.current);
    }

    // Set up a new timer for 7 minutes (420000 milliseconds)
    resetTimer.current = setTimeout(() => {
      resetState();
    }, 420000);

    // Clean up on component unmount
    return () => {
      if (resetTimer.current) {
        clearTimeout(resetTimer.current);
      }
    };
  }, [resetState]);


  return (
    <>
      <NavBar />
      <Box sx={{ width: '100%', overflowX: 'auto' }}>
        {error && (
          <Box textAlign="center" color="red">{error}</Box>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {isLoading ? (
              <CircularProgress color="secondary" />
            ) : (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 300 }} size="small" aria-label="inventory data table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell style={{ cursor: 'pointer' }}>Location</StyledTableCell>

                      <StyledTableCell align="center">OHQty</StyledTableCell>
                      <StyledTableCell align="center">BIN</StyledTableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>

                    {aggregatedInventoryData.map((aggregatedItem, index) => (
                      <>
                        {/* Style Number Row */}
                        <TableRow key={`style-number-${index}`} style={{ backgroundColor: '#F9F6EE' }}>
                          <TableCell colSpan={6} align="center" style={{ fontSize: 18, fontWeight: 'bold' }}>
                          {aggregatedItem.Details[0]?.Style_Code} / StorePrice: ${aggregatedItem.Details[0]?.Selling_Price}
                          </TableCell>
                        </TableRow>
                        <StyledTableRow key={aggregatedItem.scanId} onClick={() => handleRowExpansion(aggregatedItem.scanId)}>
                          <StyledTableCell style={{ fontWeight: 'bolder' }}>{aggregatedItem.Location_ID}</StyledTableCell>
                          <StyledTableCell align="center" style={{ fontWeight: 'bolder' }}>{aggregatedItem.Total_QTY_On_Hand}</StyledTableCell>
                          <StyledTableCell align="center" style={{ fontWeight: 'bolder' }}>{aggregatedItem.Common_BIN || "N/A"}</StyledTableCell>

                        </StyledTableRow>

                        {expandedRows.has(aggregatedItem.scanId) && (
                          <TableRow key={`details-${aggregatedItem.Location_ID}`}>
                            <TableCell colSpan={6}>
                              <Table size="small">
                                <TableHead>
                                  <StyledTableRow style={{ backgroundColor: '#C0C0C0' }}>
                                  <StyledTableCell align='center'>SKU</StyledTableCell>
                                    <StyledTableCell align='center'>Size</StyledTableCell>
                                    <StyledTableCell align='center'>OHQty</StyledTableCell>
                                    <StyledTableCell align='center'>InTransitQty</StyledTableCell>
                                    <StyledTableCell align='center'>BIN</StyledTableCell>
                                  </StyledTableRow>
                                </TableHead>
                                <TableBody>

                                  {aggregatedItem.Details.map(detail => (
                                    <StyledTableRow key={`${aggregatedItem.scanId}-${detail.PLU_SKU}`}>
                                      <StyledTableCell style={{ fontWeight: 'bolder' }}>{detail.PLU_SKU}</StyledTableCell>
                                      <StyledTableCell align="center" style={{ fontWeight: 'bolder' }}>{detail.Size1_Code}</StyledTableCell>
                                      <StyledTableCell align="center" style={{ fontWeight: 'bolder' }}>{detail.QTY_On_Hand}</StyledTableCell>
                                      <StyledTableCell align='center' style={{fontWeight: 'bolder'}}>{detail.InTransitQty}</StyledTableCell>
                                      <StyledTableCell align='center' style={{fontWeight: 'bolder'}}>{detail.BIN}</StyledTableCell>
                                      {/* More details can be shown here */}
                                    </StyledTableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="center" m={4}>
          <BlinkingButton onClick={() => navigate('/userbin')}>
            Picked
          </BlinkingButton>
        </Box>
      </Box>
    </>
  );
};

export default BarcodeScannerComponentBulk;