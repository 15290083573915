// NavBar.tsx
import * as React from 'react';
import { AppBar, Toolbar, Button, Typography, Box, Menu, MenuItem, Drawer, IconButton, useMediaQuery, Theme, List, ListItem, ListItemText, Avatar, Fade, Collapse } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from '../App';
import { useContext, useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import logo from './logo.jpg';
import StylePerformanceByTR from '../Reports/StylePerformanceByTR';
import StylePerformanceByLocation from '../Reports/StylePerformanceByLocation';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';

interface NavItem {
  name: string;
  path?: string;
  action?: () => void;
  scopes: string[];
  children?: NavItem[];
}


const NavBar: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  //const [loginCredentials, setLoginCredentials] = useState({ email: "", password: "" });
  const {
    authenticatedUser,
    setAuthenticatedUser,
  }: { authenticatedUser: IUserSessionInfo; setAuthenticatedUser: any } =
    useContext(UserContext);
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const [reportDialogOpen, setReportDialogOpen] = useState(false);
  const [styleReportDialogOpen, setStyleReportDialogOpen] = useState(false);

  const [nestedMenuOpen, setNestedMenuOpen] = useState<string | null>(null);
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [menuOpen, setMenuOpen] = useState<Record<string, boolean>>({});
  // Function to open the Report Generator Dialog




  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNestedMenuClick = (name: string) => {
    setNestedMenuOpen(nestedMenuOpen === name ? null : name);
  };
  const items: NavItem[] = [
    { name: 'Home', path: '/home', scopes: ['SuperAdmin', 'Admin', 'DC', 'DC-Caledonia','dbowner'] },
    {
      name: 'Inventory',
      scopes: ['SuperAdmin', 'Admin', 'DC', 'DC-Caledonia','dbowner' ],
      children: [
        { name: 'Inventory', path: '/Sinventory', scopes: ['SuperAdmin', 'Admin','DC', 'DC-Caledonia','dbowner' ] },
        // { name: 'Global Inventory', path: '/globalInventory', scopes: ['SuperAdmin', 'Admin','DC', 'DC-Caledonia','dbowner' ] },
        
      ],
    },
  
        { name: 'Transfers', path: '/transferOrders', scopes: ['dbowner', 'DC', 'DC-Caledonia' ] },
    
  
    {
      name: 'Reports',
      scopes: ['SuperAdmin', 'Admin','DC', 'DC-Caledonia','dbowner' ],
      children: [
        { name: 'StylePerformanceByLoc', action: () => { setStyleReportDialogOpen(true); handleClose(); }, scopes: ['SuperAdmin', 'Admin','DC', 'DC-Caledonia','dbowner' ] },
        { name: 'StylePerformanceByTR', action: () => { setReportDialogOpen(true); handleClose(); }, scopes: ['SuperAdmin', 'Admin','DC', 'DC-Caledonia','dbowner' ] },
        { name: 'Performance', path: '/style/performance', scopes: ['SuperAdmin', 'Admin','DC', 'DC-Caledonia','dbowner' ] },
      ],
    },
    { name: 'BIN LookUp', path: '/userbin', scopes: ['SuperAdmin', 'Admin', 'User','dbowner'] },
    { name: 'Inventory Scan', path: '/inventoryScan', scopes: ['SuperAdmin', 'Admin','DC', 'DC-Caledonia','dbowner' ] },
    {
      name: 'Users',
      scopes: ['SuperAdmin','dbowner'],
      children: [
        { name: 'Register', path: '/user/register', scopes: ['SuperAdmin','dbowner'] },
        { name: 'Users', path: '/userList', scopes: ['SuperAdmin','dbowner'] },
      ],
    },


  ];

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>, name: string) => {
    setAnchorElNav(event.currentTarget);
    setMenuOpen((prev) => ({ ...prev, [name]: true }));
  };

  const handleCloseNavMenu = (name: string) => {
    setAnchorElNav(null);
    setMenuOpen((prev) => ({ ...prev, [name]: false }));
  };
  const loginUserFromSession = async () => {
    try {
      let fetchRes = await fetch("/auth/user", {
        method: "POST",
        credentials: "include",
      });
      let response = await fetchRes.json();
      if (response.user._id === undefined) {
        navigate("/user/login");
      } else {
        setAuthenticatedUser(response.user);
        navigate("/");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleLogout = async () => {
    try {
      let fetchRes = await fetch("/auth/logout", {
        credentials: "include",
      });
      await fetchRes.json();
      setAuthenticatedUser({
        _id: null,
        email: "",
        first_name: "",
        last_name: "",
        scope: "",
      });
    } catch (e) {
      console.log(e);
    }
  };



  // Use optional chaining to safely access nested properties
  const firstNameInitial = authenticatedUser?.first_name?.[0] ?? '';
  const lastNameInitial = authenticatedUser?.last_name?.[0] ?? '';




  // Conditional rendering based on user scope
  const renderNavItems = (items: NavItem[]) => (
    items.filter(item => authenticatedUser && item.scopes.includes(authenticatedUser.scope)).map((item, index) => (
      item.children ? (
        <React.Fragment key={index}>
          <ListItem button onClick={() => handleNestedMenuClick(item.name)}>
            <ListItemText primary={item.name} />
            {nestedMenuOpen === item.name ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
          </ListItem>
          <Collapse in={nestedMenuOpen === item.name} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {renderNavItems(item.children)}
            </List>
          </Collapse>
        </React.Fragment>
      ) : (
        <ListItem
          button
          key={index}
          onClick={() => item.action && item.action()}
          {...(item.path && { component: Link, to: item.path })}
        >
          <ListItemText primary={item.name} />
        </ListItem>
      )
    ))
  );

  const renderDesktopNavItem = (item: NavItem) => (
    <>
      <Button
        key={item.name}
        onClick={(e) => item.children && item.children.length ? handleOpenNavMenu(e, item.name) : item.path ? navigate(item.path) : null}
        style={{ color: 'White', fontWeight: "bold", width: '100%', fontSize: '12pt' }}
        sx={{ my: 2, display: 'block' }}
      >
        {item.name}
      </Button>
      {item.children && (
        <Menu
          id={`menu-${item.name}`}
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={!!menuOpen[item.name]}
          onClose={() => handleCloseNavMenu(item.name)}
          sx={{
            display: 'block',
          }}
        >
          {item.children.map((child, childIndex) => (
            <MenuItem key={childIndex} onClick={() => {
              handleCloseNavMenu(item.name);
              if (child.path) {
                navigate(child.path);
              } else if (child.action) {
                child.action();
              }
            }}>
              <Typography textAlign="center">{child.name}</Typography>
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );


  return (
    <>
      <AppBar position="static" >

        <Toolbar>
          {isMobile && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={() => setDrawerOpen(!drawerOpen)}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            <Link to='/'>
              <img src={logo} alt="SVP-Sports" style={{ height: '50px', marginRight: '10px', cursor: 'pointer' }} />
            </Link>
          </Box>
          <Box sx={{ display: 'flex', flexGrow: 5, justifyContent: 'center' }}>
            {!isMobile && <>
              {items.filter(item => authenticatedUser && item.scopes.includes(authenticatedUser.scope)).map(renderDesktopNavItem)}
            </>}
          </Box>
          <IconButton
            edge="end"
            color="inherit"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
          >
            <Avatar style={{ background: "#FFFFFF", color: "black", fontWeight: "bolder" }}>{firstNameInitial}{lastNameInitial}</Avatar>
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
          >
            {authenticatedUser && authenticatedUser._id ? (
              <div>
                <Button
                  color="inherit"
                  id="fade-button"
                  aria-controls={open ? 'fade-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleMenu}
                >
                  <Avatar>{firstNameInitial}{lastNameInitial}</Avatar>
                </Button>
                <Menu
                  id="fade-menu"
                  MenuListProps={{
                    'aria-labelledby': 'fade-button',
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  TransitionComponent={Fade}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose} component={Link} to="/user/profile">Profile</MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </div>
            ) : (
              <>
                <Button color="inherit" component={Link} to="/auth/login" onClick={() => {

                  loginUserFromSession();
                }}>LogIn</Button>

              </>
            )}

          </Menu>

        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <List>
          {renderNavItems(items)}
        </List>
      </Drawer>
      <StylePerformanceByTR open={reportDialogOpen} setOpen={setReportDialogOpen} />
      <StylePerformanceByLocation open={styleReportDialogOpen} setOpen={setStyleReportDialogOpen} />
    </>
  );
};

export default NavBar;

