import React, { useState, useEffect, useMemo } from 'react';
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, styled, tableCellClasses, Box, Grid, CircularProgress, useTheme, useMediaQuery, Typography, Card, CardContent, Button, Collapse, AppBar, Toolbar } from '@mui/material';
import NavBar from '../Navigation/Navigation';


interface InventoryData {
  PLU_SKU: string;
  QTY_On_Hand: number;
  QTY_Received: number;
  QTY_Sold: number;
  Size1_Code: string;
  Location_ID: string;
  Style_Code: string;
  BIN: string;
  Sold_Qty_45: number;
  Sold_Price_45: number;
  Last_Received_Date: Date;
  TotalAgedDays: number;
  SoldQty_Last30Days: number;
  SoldQty_Last60Days: number;
  SoldPrice_Last30Days: number;
  SoldPrice_Last60Days: number;
  TotalInTransitQty: number;
  TotalTransOutQty: number;

}

interface AggregatedData {
  Location_ID: string;
  Total_QTY_On_Hand: number;
  Total_QTY_Received: number;
  Total_QTY_Sold: number;
  Common_BIN: string | null;
  Total_Sold_Qty_45: number;
  Total_Sold_Price_45: number;
  TotalAgedDays: number;
  Details: InventoryData[];
  SoldQty_Last30Days: number;
  SoldQty_Last60Days: number;
  SoldPrice_Last30Days: number;
  SoldPrice_Last60Days: number;
  TotalInTransitQty: number;
  TotalTransOutQty: number;
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
    // Grey for odd rows
  },
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.common.white, // White for even rows
  },
  '&:last-child td, &:last-child th': {
    border: 0,

  },
}));



const BarcodeScannerComponent: React.FC = () => {
  const [barcode, setBarcode] = useState('');
  const [inventoryData, setInventoryData] = useState<InventoryData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingStatusMessage, setLoadingStatusMessage] = useState("")
  const [sortAsc, setSortAsc] = useState(false);
  const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set());
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#FFE8D6",
      color: theme.palette.common.black,
      fontSize: isSmallScreen ? 10 : 16, // Smaller font size on small screens
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: isSmallScreen ? 8 : 14
      // Smaller font size on small screens
    },
  }));

  // Function to aggregate data by location
  const aggregateData = (inventoryData: InventoryData[]): AggregatedData[] => {
    const aggregationMap = new Map<string, AggregatedData>();

    inventoryData.forEach(item => {
      let aggregatedItem = aggregationMap.get(item.Location_ID);

      if (!aggregatedItem) {
        aggregatedItem = {
          Location_ID: item.Location_ID,
          Total_QTY_On_Hand: 0,
          Total_QTY_Received: 0,
          Total_Sold_Price_45: 0,
          Total_Sold_Qty_45: 0,
          Total_QTY_Sold: 0,
          TotalAgedDays: 0,
          SoldQty_Last30Days: 0,
          SoldQty_Last60Days: 0,
          SoldPrice_Last30Days: 0,
          SoldPrice_Last60Days: 0,
          TotalInTransitQty: 0,
          TotalTransOutQty: 0,
          Common_BIN: item.BIN, // Initialize with the first BIN
          Details: []
        };
        aggregationMap.set(item.Location_ID, aggregatedItem);
      }
      aggregatedItem.TotalInTransitQty += item.TotalInTransitQty;
      aggregatedItem.TotalTransOutQty += item.TotalTransOutQty;
      aggregatedItem.SoldQty_Last30Days += item.SoldQty_Last30Days;
      aggregatedItem.SoldPrice_Last30Days += item.SoldPrice_Last30Days;
      aggregatedItem.SoldQty_Last60Days += item.SoldQty_Last60Days + item.SoldQty_Last30Days;
      aggregatedItem.SoldPrice_Last60Days += item.SoldPrice_Last60Days + item.SoldPrice_Last30Days;
      aggregatedItem.Total_QTY_On_Hand += item.QTY_On_Hand;
      aggregatedItem.Total_QTY_Received += item.QTY_Received;
      aggregatedItem.Total_QTY_Sold += item.QTY_Sold;
      aggregatedItem.Total_Sold_Price_45 += item.Sold_Price_45;
      aggregatedItem.Total_Sold_Qty_45 += item.Sold_Qty_45;
      // Only calculate TotalAgedDays if any of the quantities are non-zero
      if (aggregatedItem.Total_QTY_On_Hand > 0 || aggregatedItem.Total_QTY_Sold > 0 || aggregatedItem.Total_QTY_Received > 0) {
        const lastReceivedDate = new Date(item.Last_Received_Date);
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        const differenceInTime = currentDate.getTime() - lastReceivedDate.getTime();
        const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
        aggregatedItem.TotalAgedDays = differenceInDays;
      }

      // Check if BIN is the same for all items
      if (aggregatedItem.Common_BIN !== item.BIN) {
        aggregatedItem.Common_BIN = null; // Set to null if different BINs are found
      }



      // Round Total_Sold_Price_45 for each aggregated item
      aggregationMap.forEach(aggregatedItem => {
        aggregatedItem.SoldPrice_Last30Days = parseFloat(aggregatedItem.SoldPrice_Last30Days.toFixed(2));
      });


      // Round Total_Sold_Price_45 for each aggregated item
      aggregationMap.forEach(aggregatedItem => {
        aggregatedItem.Total_Sold_Price_45 = parseFloat(aggregatedItem.Total_Sold_Price_45.toFixed(2));
      });

      // Round Total_Sold_Price_45 for each aggregated item
      aggregationMap.forEach(aggregatedItem => {
        aggregatedItem.SoldPrice_Last60Days = parseFloat(aggregatedItem.SoldPrice_Last60Days.toFixed(2));
      });

      aggregatedItem.Details.push(item);
    });

    return Array.from(aggregationMap.values());
  };



  // useMemo to memoize aggregated data
  const aggregatedInventoryData = useMemo(() => {
    return aggregateData(inventoryData);
  }, [inventoryData]);

  // Function to handle row expansion
  const handleRowExpansion = (locationId: string) => {
    const newExpandedRows = new Set(expandedRows);
    if (expandedRows.has(locationId)) {
      newExpandedRows.delete(locationId);
    } else {
      newExpandedRows.add(locationId);
    }
    setExpandedRows(newExpandedRows);
  };

  const fetchInventoryData = async (barcode: string) => {
    setIsLoading(true);
    try {
      setLoadingStatusMessage("Fetching data...")
      const response = await fetch(`/stylePerformance/${barcode}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json() as InventoryData[];
      console.log("Fetched data:", data);

      setInventoryData(data);
      setLoadingStatusMessage((v) => "Calculating quantities")
      sortData();
    } catch (error) {
      console.error('Error fetching inventory data:', error);
      setInventoryData([]);
    } finally {
      setIsLoading(false);
    }
  };



  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key !== 'Enter') {
        setBarcode((prev) => prev + event.key);
      } else {
        if (barcode.trim() !== '') {
          fetchInventoryData(barcode.trim());
          setBarcode('');
        }
      }

    };

    window.addEventListener('keypress', handleKeyPress);

    return () => {
      window.removeEventListener('keypress', handleKeyPress);
    };

  }, [barcode]);

  const sortData = () => {
    setInventoryData(prevData =>
      [...prevData].sort((a, b) =>
        sortAsc ? b.Location_ID.localeCompare(a.Location_ID) : a.Location_ID.localeCompare(b.Location_ID)
      )
    );
    setSortAsc(!sortAsc); // Toggle sort order
  };

  useEffect(() => {
    // Initial sort when component mounts
    sortData();
  }, []);

  const styleNumberRow = inventoryData.length > 0 ? (
    <TableRow style={{ backgroundColor: '#F9F6EE' }}>
      <TableCell colSpan={14} align="center" style={{ fontSize: 18, fontWeight: 'bold' }}>
        {inventoryData[0].Style_Code}

      </TableCell>
    </TableRow>
  ) : null;

  const renderTableView = () => (
    <>
      <AppBar position="fixed">
        
          <NavBar />
       
      </AppBar>

      <Box sx={{ width: '100%', overflowX: 'auto' }}>
        <Grid container style={{ marginTop: 60 }}>
          <Grid item xs={18} sm={16} md={12} lg={12} xl={8}>

            {isLoading ? <Grid item xs={10} padding={15}><CircularProgress color="secondary" variant="indeterminate" /> </Grid> : (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 550 }} size="small" aria-label="a dense table">
                  <TableHead style={{ backgroundColor: '#F5F5DC' }}>
                    <TableRow>
                      <StyledTableCell onClick={sortData} style={{ cursor: 'pointer' }}>Location ID</StyledTableCell>
                      <StyledTableCell align="center">QTY Received</StyledTableCell>
                      <StyledTableCell align="center">QTY Sold</StyledTableCell>
                      <StyledTableCell align="center">QTY On Hand</StyledTableCell>
                      <StyledTableCell align="center">InTransit</StyledTableCell>
                      <StyledTableCell align="center">TROut</StyledTableCell>
                      <StyledTableCell align="center">SoldQty_30</StyledTableCell>
                      <StyledTableCell align="center">SoldCost_30</StyledTableCell>
                      <StyledTableCell align="center">SoldQty_45</StyledTableCell>
                      <StyledTableCell align="center">SoldCost_45</StyledTableCell>
                      <StyledTableCell align="center">SoldQty_60</StyledTableCell>
                      <StyledTableCell align="center">SoldCost_60</StyledTableCell>
                      <StyledTableCell align="center">Aged Days</StyledTableCell>
                      <StyledTableCell align="center">BIN</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {styleNumberRow}
                    {aggregatedInventoryData.map(aggregatedItem => (
                      <>
                        <StyledTableRow key={aggregatedItem.Location_ID} onClick={() => handleRowExpansion(aggregatedItem.Location_ID)}>
                          <StyledTableCell style={{ fontWeight: 'bolder' }}>{aggregatedItem.Location_ID}</StyledTableCell>
                          <StyledTableCell align="center">{aggregatedItem.Total_QTY_Received}</StyledTableCell>
                          <StyledTableCell align="center">{aggregatedItem.Total_QTY_Sold}</StyledTableCell>
                          <StyledTableCell align="center">{aggregatedItem.Total_QTY_On_Hand}</StyledTableCell>
                          <StyledTableCell align="center">{aggregatedItem.TotalInTransitQty}</StyledTableCell>
                          <StyledTableCell align="center">{aggregatedItem.TotalTransOutQty}</StyledTableCell>
                          <StyledTableCell align="center">{aggregatedItem.SoldQty_Last30Days}</StyledTableCell>
                          <StyledTableCell align="center">$ {aggregatedItem.SoldPrice_Last30Days}</StyledTableCell>
                          <StyledTableCell align="center">{aggregatedItem.Total_Sold_Qty_45}</StyledTableCell>
                          <StyledTableCell align="center">$ {aggregatedItem.Total_Sold_Price_45}</StyledTableCell>
                          <StyledTableCell align="center">{aggregatedItem.SoldQty_Last60Days}</StyledTableCell>
                          <StyledTableCell align="center">$ {aggregatedItem.SoldPrice_Last60Days}</StyledTableCell>
                          <StyledTableCell align="center">{aggregatedItem.TotalAgedDays}</StyledTableCell>
                          <StyledTableCell align="center">
                            {aggregatedItem.Common_BIN ? aggregatedItem.Common_BIN : "N/A"}
                          </StyledTableCell>

                        </StyledTableRow>
                        {expandedRows.has(aggregatedItem.Location_ID) && (
                          <TableRow key={`details-${aggregatedItem.Location_ID}`}>
                            <TableCell colSpan={6}>
                              <Table size="small">
                                <TableHead>
                                  <TableRow style={{ backgroundColor: '#C0C0C0' }}>
                                    <TableCell align='center'>PLU SKU</TableCell>
                                    <TableCell align='center'>Size</TableCell>
                                    <TableCell align='center'>QTY Received</TableCell>
                                    <TableCell align='center'>QTY Sold</TableCell>
                                    <TableCell align='center'>QTY On Hand</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {aggregatedItem.Details.map(detail => (
                                    <StyledTableRow key={detail.PLU_SKU}>
                                      <TableCell align='center'>{detail.PLU_SKU}</TableCell>
                                      <TableCell align='center'>{detail.Size1_Code}</TableCell>
                                      <TableCell align='center'>{detail.QTY_Received}</TableCell>
                                      <TableCell align='center'>{detail.QTY_Sold}</TableCell>
                                      <TableCell align='center'>{detail.QTY_On_Hand}</TableCell>
                                    </StyledTableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}

          </Grid>

        </Grid>

      </Box>
    </>
  );

  // Expanded details state
  const [expandedDetails, setExpandedDetails] = useState<string | null>(null);

  // Function to handle expanding/collapsing card details
  const handleToggleDetails = (locationId: string) => {
    setExpandedDetails(prevState => prevState === locationId ? null : locationId);
  };


  // Custom styled components
  const CustomCard = styled(Card)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper, // Use theme colors for better theming
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Custom shadow for depth
    '&:hover': {
      boxShadow: '0 6px 12px rgba(0, 0, 0, 0.2)', // Enhanced shadow on hover
    },
  }));

  const CustomCardContent = styled(CardContent)({
    textAlign: 'center', // Center align the text
    paddingBottom: '16px !important', // Override padding-bottom
  });

  const CustomTypographyTitle = styled(Typography)({
    color: '#0F2143',
    backgroundColor: "#D3D3D3", // Example: blue color for the title
    fontWeight: 'bold',
  });

  const CustomTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary, // Use theme for text color
  }));

  // Function to render card view for smaller screens
  const renderCardView = () => (
    <>
      <AppBar position="fixed">
       
          <NavBar />
      
      </AppBar>
      <Box sx={{ pt: 8 }}> {/* Adjust top padding based on NavBar's height */}
        {inventoryData.length > 0 && (
          <Typography variant="h5" align="center" gutterBottom style={{ fontWeight: 'bold' }}>
            {inventoryData[0].Style_Code}
          </Typography>
        )}
        <Grid container spacing={2}>
          {aggregatedInventoryData.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <CustomCard variant="outlined">
                <CustomCardContent>
                  <CustomTypographyTitle variant="h6" onClick={() => handleToggleDetails(item.Location_ID)} style={{ cursor: 'pointer' }}>
                    {item.Location_ID}
                  </CustomTypographyTitle>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Typography variant="body2">OHQty:</Typography>
                      <Typography variant="body1">{item.Total_QTY_On_Hand}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">QtySold:</Typography>
                      <Typography variant="body1">{item.Total_QTY_Sold}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">QtyReceived:</Typography>
                      <Typography variant="body1">{item.Total_QTY_Received}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">BIN:</Typography>
                      <Typography variant="body1">{item.Common_BIN ? item.Common_BIN : "N/A"}</Typography>
                    </Grid>
                  </Grid>
                  <Button onClick={() => handleToggleDetails(item.Location_ID)} size="small">
                    {expandedDetails === item.Location_ID ? 'View Less' : 'View More'}
                  </Button>
                  <Collapse in={expandedDetails === item.Location_ID}>
                    {renderAdditionalInfoTable(item)}
                    {renderDetailsTable(item.Details)}
                  </Collapse>
                </CustomCardContent>
              </CustomCard>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );

  const renderAdditionalInfoTable = (item: AggregatedData) => (
    <Table size="small">
      <TableHead>
        <TableRow>
          <StyledTableCell style={{ fontWeight: 'bolder' }}>Detail</StyledTableCell>
          <StyledTableCell align="right" style={{ fontWeight: 'bolder' }}>Value</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <StyledTableRow>
          <StyledTableCell component="th" scope="row" style={{ fontWeight: 'bolder' }}>InTransit Qty</StyledTableCell>
          <StyledTableCell align="right" style={{ fontWeight: 'bolder' }}>{item.TotalInTransitQty}</StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell style={{ fontWeight: 'bolder' }}>TransOut Qty</StyledTableCell>
          <StyledTableCell align="right" style={{ fontWeight: 'bolder' }}>{item.TotalTransOutQty}</StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell style={{ fontWeight: 'bolder' }}>SoldQty In 30D</StyledTableCell>
          <StyledTableCell align="right" style={{ fontWeight: 'bolder' }}>{item.SoldQty_Last30Days}</StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell style={{ fontWeight: 'bolder' }}>SoldRetail In 30D</StyledTableCell>
          <StyledTableCell align="right" style={{ fontWeight: 'bolder' }}>{item.SoldPrice_Last30Days}</StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell style={{ fontWeight: 'bolder' }}>SoldQty In 45D</StyledTableCell>
          <StyledTableCell align="right" style={{ fontWeight: 'bolder' }}>{item.Total_Sold_Qty_45}</StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell style={{ fontWeight: 'bolder' }}>SoldRetail In 45D</StyledTableCell>
          <StyledTableCell align="right" style={{ fontWeight: 'bolder' }}>{item.Total_Sold_Price_45}</StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell style={{ fontWeight: 'bolder' }}>SoldQty In 60D</StyledTableCell>
          <StyledTableCell align="right" style={{ fontWeight: 'bolder' }}>{item.SoldQty_Last60Days}</StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell style={{ fontWeight: 'bolder' }}>SoldRetail In 60D</StyledTableCell>
          <StyledTableCell align="right" style={{ fontWeight: 'bolder' }}>{item.SoldPrice_Last60Days}</StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell style={{ fontWeight: 'bolder' }}>Aged Days</StyledTableCell>
          <StyledTableCell align="right" style={{ fontWeight: 'bolder' }}>{item.TotalAgedDays}</StyledTableCell>
        </StyledTableRow>

        {/* Add more rows as needed */}
      </TableBody>
    </Table>
  );

  // Function to render detailed view for each item
  const renderDetailsTable = (details: InventoryData[]) => (
    <Table size="small">
      <TableHead>
        <TableRow style={{ backgroundColor: '#C0C0C0' }}>
          <StyledTableCell align='center'>SKU</StyledTableCell>
          <StyledTableCell align='center'>Size</StyledTableCell>
          <StyledTableCell align='center'>QTY RCV</StyledTableCell>
          <StyledTableCell align='center'>QTY Sold</StyledTableCell>
          <StyledTableCell align='center'>OH Qty</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {details.map(detail => (
          <StyledTableRow key={detail.PLU_SKU}>
            <StyledTableCell align='center' style={{ fontWeight: 'bolder' }}>{detail.PLU_SKU}</StyledTableCell>
            <StyledTableCell align='center' style={{ fontWeight: 'bolder' }}>{detail.Size1_Code}</StyledTableCell>
            <StyledTableCell align='center' style={{ fontWeight: 'bolder' }}>{detail.QTY_Received}</StyledTableCell>
            <StyledTableCell align='center' style={{ fontWeight: 'bolder' }}>{detail.QTY_Sold}</StyledTableCell>
            <StyledTableCell align='center' style={{ fontWeight: 'bolder' }}>{detail.QTY_On_Hand}</StyledTableCell>
          </StyledTableRow>
        ))}
      </TableBody>
    </Table>
  );
  return (
    <Box sx={{ flexGrow: 1 }}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        isSmallScreen ? renderCardView() : renderTableView() // Assuming renderTableView is defined elsewhere
      )}
    </Box>
  );
};

export default BarcodeScannerComponent;