import React from 'react';
import { Text } from 'recharts';
import { useTheme, useMediaQuery } from '@mui/material';

interface CustomTickProps {
  x: number;
  y: number;
  payload: any;
  index: number;
}

const CustomTick: React.FC<CustomTickProps> = ({ x, y, payload, index }) => {
  const theme = useTheme();
  const isXSmall = useMediaQuery(theme.breakpoints.down('xs'));

  // Example strategy for reducing tick overlap on small screens
  // This skips rendering of some ticks based on an arbitrary condition, like index
  // Adjust this logic as needed to balance readability and density of ticks
  const shouldRenderTick = isXSmall ? index % 2 === 0 : true; // Example: skip every other tick on small screens

  return shouldRenderTick ? (
    <g transform={`translate(${x},${y})`}>
      <Text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
        {payload.value}
      </Text>
    </g>
  ) : null;
};

export default CustomTick;
